import React from "react";
import Lang from "../Elements/Lang";
import {useI18nTextOpener} from "../../../../hook/useI18nText";
import {LangInterface} from "../../../../service/CV/Interfaces/LangInterface";

type LangsListProps = {
    dataLanguages: LangInterface[];
};

const LangsList: React.FunctionComponent<LangsListProps> = ({dataLanguages}) => {
    const i18nOpener = useI18nTextOpener();

    return (
        <div className={"langs col-section"}>
            {dataLanguages.map((s) => (
                <Lang lang={s} key={i18nOpener(s.name)}/>
            ))}
        </div>
    );
};

export default LangsList;
