import React from "react";
import {useTranslation} from "react-i18next";
import {useI18nTextOpener} from "../../../../hook/useI18nText";
import Skill from "../Elements/Skill";
import {TypeForm} from "../../../../types/EnumTypeForm";
import {SoftSkillInterface} from "../../../../service/CV/Interfaces/SoftSkillInterface";

type SoftSkillsListProps = {
    dataSoftSkills: SoftSkillInterface[];
};

const SoftSkillsList: React.FunctionComponent<SoftSkillsListProps> = ({dataSoftSkills}) => {
    const {t} = useTranslation();
    const i18nOpener = useI18nTextOpener();

    return (
        <div className={"skills col-section"}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div style={{padding: "left", paddingRight: "10px"}}>
                    <h2 className={"col-title"}>{t("softskills")}</h2>
                </div>
                <div className={"col-barre"}>
                </div>
            </div>
            <div>
                {dataSoftSkills.map((s) => (
                    <Skill skill={s} key={i18nOpener(s.name)} typeSkill={TypeForm.SOFT_SKILLS} />
                ))}
            </div>
        </div>
    );
};

export default SoftSkillsList;
