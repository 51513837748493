import {setToken} from "../../utils/storage/Token"
import React, {useState} from "react";
import {PostRequest} from '../../hook/useRequest';
import './Login.scss';
import FCButton from "../../components/FCObjets/FCButton";

function Login() {

    const [password, setPassword] = useState("");
    const [login, setLogin] = useState("");
    const [error, setError] = useState<any>(null);

    const handleChange = (event: any) => {
        if (event.target.name === "password") {
            setPassword(event.target.value);
        } else if (event.target.name === "login") {
            setLogin(event.target.value);
        }
    }

    const onSubmit = async (event:React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        setError(null);
        try {
            const res = await PostRequest("/authentication/login", {
                username: login,
                password: password
            });
            setToken(res.data.token);
            window.location.reload();
        } catch (e) {
            setError("Le username ou le mot de passe ne correspondent pas !");
        }
    };

    return (
        <form className={"login"} action="" onSubmit={(event) => onSubmit(event)}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                <div className={"div_authentification"}>
                    <div className={"div_title"}>
                        <div className={"div_logo"}>
                            <img src={ `${process.env.PUBLIC_URL}/logo_app_cv.png`}
                                 alt={"logo_fc"} height={75} width={75}/>
                        </div>
                        <h3>Gestion CV</h3>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                        <div style={{display: "flex", flexDirection: "column", width: "60%"}}>
                            <div className={"labelInput"}>Utilisateur</div>
                            <input className={"inputLogin"} name={"login"} type={"text"}
                                   onChange={(event) => handleChange(event)}/>
                            <div className={"labelInput"}>Mot de passe</div>
                            <input className={"inputLogin"} name={"password"} type={"password"}
                                   onChange={(event) => handleChange(event)}/>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                        <FCButton type="submit"  label={"SE CONNECTEr"}/>
                    </div>
                    <div className={"errorLogin"} style={{}}>
                        {error}
                    </div>
                </div>
            </div>
        </form>
    )
}

export default Login;