import React from "react";
import "./FCDropdown.scss";

export interface SelectOption {
    value: string;
    label: string;
}

type DropdownProps = {
    onChangeHandler?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    label: string;
    value: string|number;
    options: SelectOption[];
    id: string

    widthDropdown?:string;
    widthLabel?:string;
    colorTextLabel?:string;
    horizontalDisplay?:boolean;
};

const FCDropdown: React.FunctionComponent<DropdownProps> = (props :DropdownProps) => {

    return (
        <div className={(props.horizontalDisplay)?"horizontalFormInput":"verticalFormInput"}>
            <label className={"FCDropdownLabel"} htmlFor={props.id} style={{width:props.widthLabel, color:props.colorTextLabel}}>{props.label}</label>
            <select className={"FCDropdown"} value={props.value} onChange={props.onChangeHandler} style={{width:props.widthDropdown}}>
                {props.options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
}

// Set default props
FCDropdown.defaultProps = {
    widthDropdown:"200px",
    widthLabel:"110px",
    horizontalDisplay:false
};

export default FCDropdown;