import React, {useContext, useState} from "react";
import {EventUpdatedContext} from "../../../store/EventUpdatedContext";
import {AddSoftSkills, UpdateSoftSkills} from "../../../service/CV/SoftSkillsService";
import {SoftSkillInterface} from "../../../service/CV/Interfaces/SoftSkillInterface";
import FCButton from "../../FCObjets/FCButton";
import FCInput from "../../FCObjets/Form/FCInput";
import {CallbackErrorModalCV, CallbackFinishedModalCV} from "./ModalCVForm";

type FormSoftSkillListProps = {
    onFinished: CallbackFinishedModalCV;
    onError: CallbackErrorModalCV;
    dataSkills: SoftSkillInterface | undefined;
    idUserData: number;
};

const FormSoftSkill: React.FunctionComponent<FormSoftSkillListProps> = ({dataSkills, idUserData, onFinished, onError}) => {

    const [inputNom, setInputNom] = useState(dataSkills ? dataSkills.name : "");
    const {setUpdated} = useContext(EventUpdatedContext)

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        onError(null);

        let skill: SoftSkillInterface = {
            softskills_id: dataSkills ? dataSkills.softskills_id : -1,
            lang: "fr",
            name: inputNom,
            user_data: undefined
        };

        if (dataSkills) {
            UpdateSoftSkills(idUserData, skill).then(() => {
                setUpdated();
                onFinished();
            }).catch((err) => onError(err));
        } else {
            AddSoftSkills(idUserData, skill).then(() => {
                setUpdated();
                onFinished();
            }).catch((err) => onError(err));
        }
    }

    return (
        <form>
            <div>
                <FCInput onChangeHandler={(e) => setInputNom(e.target.value)} label={"Nom :"} id={"Nom"}
                         value={inputNom}/>
            </div>
            <div style={{textAlign: "right"}}>
                <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit}/>
            </div>
        </form>
    );
}

export default FormSoftSkill;