import jwt from "jsonwebtoken"

const tokenKey = "authToken";

const getToken = () : string | null => {
    return window.localStorage.getItem(tokenKey);
}

const getIdWithToken = (): number => {
    const t = window.localStorage.getItem(tokenKey);
    if (t) {
        const r: any = jwt.decode(t.split(" ")[1]);
        if (r?.id_user) {
            return r?.id_user;
        }
    }
    return -1;
}

const getFirstNameWithToken = (): string => {
    const t = window.localStorage.getItem(tokenKey);
    if (t) {
        const r: any = jwt.decode(t.split(" ")[1]);
        if (r?.firstname) {
            return r?.firstname;
        }
    }
    return "";
}

const getLastNameWithToken = (): string => {
    const t = window.localStorage.getItem(tokenKey);
    if (t) {
        const r: any = jwt.decode(t.split(" ")[1]);
        if (r?.lastname) {
            return r?.lastname;
        }
    }
    return "";
}

const setToken = (token: string):void => {
    window.localStorage.setItem(tokenKey, token);
}

const clearToken = () : void => {
    window.localStorage.removeItem(tokenKey);
}

const verifyToken = () : boolean => {
    const t = window.localStorage.getItem(tokenKey);
    if (t) {
        const decodeToken: any = jwt.decode(t.split(" ")[1]);
        if (!decodeToken) {
            return false;
        }
        if (decodeToken.exp < Date.now() / 1000) {
            clearToken();
        }
        return true
    }
    return false;
}

export {
    getToken,
    getIdWithToken,
    getLastNameWithToken,
    getFirstNameWithToken,
    setToken,
    clearToken,
    verifyToken
};