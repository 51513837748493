import {faUniversity} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useI18nTextOpener} from "../../../../hook/useI18nText";
import Section from "../Elements/Section";
import Formation from "../Elements/Formation";
import {FormationInterface} from "../../../../service/CV/Interfaces/FormationInterface";

type FormationListProps = {
    dataFormations: FormationInterface[];
};

const FormationList: React.FunctionComponent<FormationListProps> = ({dataFormations}) => {
    const i18nOpener = useI18nTextOpener();

    return (
        <Section className={"formations"}
                 title={{
                     fr: "Formations et certifications",
                     en: "Education & certificates",
                 }}
                 icon={faUniversity}>
            {dataFormations.map((e) => (
                <Formation data={e} key={i18nOpener(e.title)}/>
            ))}
        </Section>
    );
};

export default FormationList;
