import React from "react";
import {useTranslation} from "react-i18next";
import {useI18nTextOpener} from "../../../../hook/useI18nText";
import Skill from "../Elements/Skill";
import {TypeForm} from "../../../../types/EnumTypeForm";
import {HardSkillInterface} from "../../../../service/CV/Interfaces/HardSkillInterface";

type HardSkillsListProps = {
    dataHardSkills: HardSkillInterface[];
};

const HardSkillsList: React.FunctionComponent<HardSkillsListProps> = ({dataHardSkills}) => {
    const {t} = useTranslation();
    const i18nOpener = useI18nTextOpener();

    return (
        <div className={"skills col-section"}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div style={{padding: "left", paddingRight: "3px"}}>
                    <h2 className={"col-title"}>{t("header_skills")}</h2>
                </div>
                <div className={"col-barre"}>

                </div>
            </div>
            <div>
                {dataHardSkills.map((s) => (
                    <Skill skill={s} key={i18nOpener(s.name)} typeSkill={TypeForm.HARD_SKILLS}/>
                ))}
            </div>
        </div>
    );
};

export default HardSkillsList;
