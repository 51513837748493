import React from "react";
import {useTranslation} from "react-i18next";
import {ProjectInterface} from "../../../../service/CV/Interfaces/Project";
import Project from "../Elements/Project";

type SideProjectsListProps = {
    projects: ProjectInterface[];
};

const ProjectsList: React.FunctionComponent<SideProjectsListProps> = ({projects}) => {
    const {t} = useTranslation();

    if (projects.length == 0) {
        return null;
    }
    return (
        <div className={"projects col-section"}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div style={{padding: "left", paddingRight: "3px"}}>
                    <h2 className={"col-title"}>{t("header_side_projets")}</h2>
                </div>
                <div className={"col-barre"}/>
            </div>
            {projects.map((p) => (
                <Project project={p} key={p.titre}/>
            ))}
        </div>
    );
};

export default ProjectsList;
