import React from "react";
import "./FCInput.scss";

type CheckboxProps = {
    onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    value: boolean;
    id: string;

    widthInput?: string;
    widthLabel?: string;
};

const FCCheckbox: React.FunctionComponent<CheckboxProps> = (props: CheckboxProps) => {

    return (
        <div>
            <label className={"FCCheckboxLabel"} htmlFor={props.id}
                   style={props.label === "" ? {width: "0px"} : {width: props.widthLabel}}>{props.label}</label>
            <input className={"FCCheckbox"} type="checkbox" checked={props.value} onChange={props.onChangeHandler}
                   style={{width: props.widthInput}} id={props.id}
                   name={props.id}/>
        </div>
    );
}

// Set default props
FCCheckbox.defaultProps = {
    widthInput: "200px",
    widthLabel: "110px",
};

export default FCCheckbox;