import React, {useContext, useEffect} from "react";
import "./Page.scss";
import "./Hover-form.scss";
import Header from "./Elements/Header";
import ExperiencesList from "./Lists/ExperiencesList";
import FormationsList from "./Lists/FormationsList";
import Footer from "./Elements/Footer";
import HardSkillsList from "./Lists/HardSkillsList";
import SoftSkillsList from "./Lists/SoftSkillsList";
import LangsList from "./Lists/LangsList";
import MyStack from "./Elements/MyStack";
import {EditableCvContext} from "../../../store/CV/EditableCvContext";
import ManageStyleCV from "../../../utils/CV/ManageStyleCV";
import {CVInterface} from "../../../service/CV/Interfaces/CVInterface";
import ProjectsList from "./Lists/ProjectsList";

type PageProps = {
    data: CVInterface;
    editable: boolean;
    numPage: number;
    modeDoublePage: boolean;
};

const Page: React.FunctionComponent<PageProps> = ({data, editable, numPage, modeDoublePage}) => {
    const {setEditableCV} = useContext(EditableCvContext);

    useEffect(() => {
        setEditableCV(editable);
    }, [setEditableCV, editable]);

    let colorBackSkills = new ManageStyleCV(data.personalData?.user_data?.style).getBackgroundSkills();
    return (
        <div id={"page-to-print-cv"} className={"page div_print marginTop"}>

            <Header personalData={data.personalData}/>

            <div className={"content"}>
                <div className={"row row-exp"}>
                    <div className={"main"}>
                        {
                            (numPage === 1) &&
                            <ExperiencesList dataExperiences={data.experiences_p1}/>
                        }
                        {
                            (!modeDoublePage || (modeDoublePage && numPage === 2) &&
                            data.experiences_p2 && <ExperiencesList dataExperiences={data.experiences_p2} />)
                        }
                        {
                            (!modeDoublePage || (modeDoublePage && numPage === 2)) &&
                            <FormationsList dataFormations={data.formations}/>
                        }
                    </div>
                    <div className={"col"} style={{backgroundColor: colorBackSkills}}>
                        <HardSkillsList dataHardSkills={data.hardSkills}/>
                        <SoftSkillsList dataSoftSkills={data.softSkills}/>
                        <ProjectsList projects={data.projects} />
                        <MyStack dataMyStack={data.myStack}/>
                        <LangsList dataLanguages={data.langs}/>
                    </div>
                </div>
            </div>
            <Footer personalData={data.personalData}/>
        </div>
    );
};

export default Page;
