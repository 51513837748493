import React, {useState} from "react";
import "./Dashboard.scss"
import {getToken, verifyToken} from "../../utils/storage/Token";
import Login from "../Login/Login";
import ContentCV from "../../components/CV/ContentCV";
import HeaderDashboard from "../../components/Dashboard/HeaderDashboard/HeaderDashboard";
import {EventUpdatedProvider} from "../../store/EventUpdatedContext";
import FooterDashboard from "../../components/Dashboard/FooterDashboard/FooterDashboard"
import ContentModifyPass from "../../components/Dashboard/ContentFC/ContentModifyPass/ContentModifyPass";

function Dashboard() {

    const [menuSelected, setMenuSelected] = useState("");

    verifyToken();
    if (!getToken()) {
        return (
            <Login/>
        )
    }

    return (
        <div className={"dashboard"}>
            <HeaderDashboard setMenuSelected={setMenuSelected}/>
            <EventUpdatedProvider>
                <div className={"contentDashboard"}>
                    <div className={"absolute layoutContent"}>
                        {
                            ((menuSelected && menuSelected === "cv") &&
                                <ContentCV editable={true}/>) ||
                            ((menuSelected && menuSelected === "password") &&
                                <ContentModifyPass/>) ||
                            <ContentCV editable={true}/>
                        }
                    </div>
                </div>
            </EventUpdatedProvider>
            <FooterDashboard/>
        </div>
    )
}

export default Dashboard;