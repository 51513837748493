import {useParams} from "react-router-dom";
import ContentCV from "../../components/CV/ContentCV";
import React from "react";

function ViewCVPublic() {

    const {name} = useParams();

    let firstname = name ? name.split("-").at(0) : "yannick";
    let lastname = name ? name.split("-").at(1) : "marchetaux";

    return (
        <React.Fragment>
            <ContentCV editable={false} lastname={lastname} firstname={firstname}/>
        </React.Fragment>
    );
}

export default ViewCVPublic;