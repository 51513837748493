import React, {useContext, useState} from "react";
import {EventUpdatedContext} from "../../../store/EventUpdatedContext";
import FCButton from "../../FCObjets/FCButton";
import FCInput from "../../FCObjets/Form/FCInput";
import {CallbackErrorModalCV, CallbackFinishedModalCV} from "./ModalCVForm";
import {ProjectInterface} from "../../../service/CV/Interfaces/Project";
import {AddProject, UpdateProject} from "../../../service/CV/ProjectService";

type FormProjectListProps = {
    onFinished: CallbackFinishedModalCV;
    onError: CallbackErrorModalCV;
    dataProject: ProjectInterface | undefined;
    idUserData: number;
};

const FormProject: React.FunctionComponent<FormProjectListProps> = ({dataProject, idUserData, onFinished, onError}) => {

    const [inputTitle, setInputTitle] = useState(dataProject ? dataProject.titre : "");
    const [inputDesc, setInputDesc] = useState(dataProject ? dataProject.shortDescription : "");
    const [inputLink, setInputLink] = useState(dataProject ? dataProject.link : "");
    const [inputImage, setInputImage] = useState(dataProject ? dataProject.images : "");
    const {setUpdated} = useContext(EventUpdatedContext)

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        onError(null);

        let projet: ProjectInterface = {
            project_id: dataProject ? dataProject.project_id : -1,
            lang: "fr",
            titre: inputTitle,
            shortDescription: inputDesc,
            link: inputLink,
            images: inputImage,
            user_data: undefined
        };

        if (dataProject) {
            UpdateProject(idUserData, projet).then(() => {
                setUpdated();
                onFinished();
            }).catch((err) => onError(err));
        } else {
            AddProject(idUserData, projet).then(() => {
                setUpdated();
                onFinished();
            }).catch((err) => onError(err));
        }
    }

    return (
        <form>
            <div>
                <FCInput onChangeHandler={(e) => setInputTitle(e.target.value)} label={"Titre :"} id={"Titre"}
                         value={inputTitle}/>
                <FCInput onChangeHandler={(e) => setInputDesc(e.target.value)} label={"Description :"} id={"desc"}
                         value={inputDesc}/>
                <FCInput onChangeHandler={(e) => setInputLink(e.target.value)} label={"Lien :"} id={"link"}
                         value={inputLink}/>
                <FCInput onChangeHandler={(e) => setInputImage(e.target.value)} label={"Image :"} id={"img"}
                         value={inputImage}/>
            </div>
            <div style={{textAlign: "right"}}>
                <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit}/>
            </div>
        </form>
    );
}

export default FormProject;