import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";

import I18nTextNode from "../../I18nTextNode";
import {ExperienceActivity, ExperienceInterface} from "../../../../service/CV/Interfaces/ExperienceInterface";
import {DisplayLevel} from "../../../../types/CV/EnumDisplayLevel";
import TypeDate from "../../../../types/TypeDate";
import {TypeForm} from "../../../../types/EnumTypeForm";
import ModalCVForm from "../../Form/ModalCVForm";
import {EditableCvContext} from "../../../../store/CV/EditableCvContext";
import ManageStyleCV from "../../../../utils/CV/ManageStyleCV";
import FCTag from "../../../FCObjets/FCTag";
import FCSpace from "../../../FCObjets/FCSpace";

type ExperienceProps = {
    data: ExperienceInterface;
};

const Experience: React.FunctionComponent<ExperienceProps> = ({data}) => {
    const {t} = useTranslation();
    const [typeForm, setTypeForm] = useState(TypeForm.EMPTY);
    const {editableCV} = useContext(EditableCvContext);

    let colorTag = new ManageStyleCV(data?.user_data?.style).getColorTagTechExp();

    return (
        <div>
            <div className={editableCV ? "experience hover-form" : "experience"}
                 onClick={() => editableCV && setTypeForm(TypeForm.EXP)}>
                <div className={"header2"}>
                    <div className={"header"}>
                        {(data.company || data.place || data.projet) && (
                            <p className={"secondary"}>
                                <span className={"company"}>
                                    <I18nTextNode text={data.projet}/>&nbsp;
                                </span> - <I18nTextNode text={data.company}/>, <I18nTextNode text={data.place}/>
                            </p>
                        )}
                    </div>
                    <div className={"date"}>
                        {data.end ? (
                            <>
                                {t('from')}{" "}
                                <span className={"from"}>
                                    <I18nTextNode text={TypeDate.convertDate(data.begin)}/>
                                </span>{" "}
                                {t('to')}{" "}
                                <span className={"to"}>
                                    <I18nTextNode text={TypeDate.convertDate(data.end)}/>
                                </span>
                            </>
                        ) : (
                            <>
                                {t('since')}{" "}
                                <span className={"from"}>
                                    <I18nTextNode text={TypeDate.convertDate(data.begin)}/>
                                </span>
                            </>
                        )}
                    </div>
                </div>

                <div className={"exp-content"}>
                    {data.description && (
                        <div className={"description"}>
                            <I18nTextNode text={data.description}/>
                        </div>
                    )}
                    {data.activities && (
                        <div className={"activities"}>
                            {data.activities.map((activity: ExperienceActivity, index) => (
                                <div
                                    className={"activity"} key={index}>
                                    <h4 className={"act-title"}>
                                        <I18nTextNode text={activity.title}/>
                                    </h4>
                                    <p className={"description"}>
                                        <I18nTextNode text={activity.description}/>
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}

                    {((data.tech && data.tech.length !== 0 && data.displayLevel !== DisplayLevel.COMPACT) && ( (colorTag === "" || colorTag === undefined) &&
                        <div className={"technologies"}>
                            <p className={"tech-title"}>{t('techs_headers')} :</p>
                            <p className={"tech-list"}>
                                <I18nTextNode text={data.tech}/>
                            </p>
                        </div>
                    )) || ((data.tech && data.tech.length !== 0 && data.displayLevel !== DisplayLevel.COMPACT) &&
                        <div className={"technologies"}>
                            <div className={"tech-list"}>
                                <FCSpace size={[4, 0]} >
                                    {data.tech.split(", ").map((value, index) =>
                                        <FCTag key={index} color={colorTag} value={value} style={{marginLeft:"5px",fontSize:"10px"}}/>
                                    )}
                                </FCSpace>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <ModalCVForm typeForm={typeForm} onModalFormFinished={() => setTypeForm(TypeForm.EMPTY)} data={data}
                         idUserData={data.user_data ? data.user_data.user_data_id : -1}/>
        </div>
    );
};

export default Experience;
