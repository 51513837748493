import React, {FC} from "react";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Dashboard from "./views/Dashboard/Dashboard";
import "./setupI18n.ts";
import "./App.scss";
import ViewCVPublic from "./views/ViewCVPublic/ViewCVPublic";
import Error404 from "./views/PageError/Error404";
import {EventUpdatedSizeProvider} from "./store/EventUpdatedWidth";

const App: FC = () => {
    return (
        <EventUpdatedSizeProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<ViewCVPublic/>}></Route>
                    <Route path="/config" element={<Dashboard/>}></Route>
                    <Route path="*" element={<Error404/>}/>
                </Routes>
            </BrowserRouter>
        </EventUpdatedSizeProvider>
    );
};

export default App;
