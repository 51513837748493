import React, {useEffect, useState} from "react";
import "./ImageStack.scss"
import {ImageStackInterface} from "../../../../service/CV/Interfaces/ImageStackInterface";
import {AppSettings} from "../../../../AppSettings";
import FCInput from "../../../FCObjets/Form/FCInput";
import FCCheckbox from "../../../FCObjets/Form/FCCheckbox";

type CallbackChanged = (imageStack: ImageStackInterface) => void;

type ImageStackProps = {
    imageStack: ImageStackInterface;
    onChanged: CallbackChanged;
};

const ImageStack: React.FunctionComponent<ImageStackProps> = ({imageStack, onChanged}) => {

    const [inputOrder, setInputOrder] = useState(0);
    const [check, setCheck] = useState(false);

    function onImageStackChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let stack: ImageStackInterface = {
            id: imageStack.id,
            check: check,
            name: imageStack.name,
            order: inputOrder
        }

        if (e.target.id === "order") {
            setInputOrder(parseInt(e.target.value))
            stack.order = parseInt(e.target.value);
        }

        if (e.target.id === "check") {
            setCheck(e.target.checked);
            stack.check = e.target.checked;
        }

        onChanged(stack);
    }

    useEffect(() => {
        setInputOrder(imageStack.order);
        setCheck(imageStack.check)
    }, [imageStack.order, imageStack.check])

    return (
        <div className={"div_root"}>
            <div className={"div_image"}>
                <FCCheckbox value={check} onChangeHandler={(e) => onImageStackChanged(e)} id={"check"} label={""} widthInput={"20px"} />
                <div className={"image_stack"}>
                    <img height={40}
                         src={AppSettings.API_ENDPOINT + "/images/stack/" + imageStack.name}
                         alt={imageStack.name}
                    />
                </div>
            </div>
            <div className={"div_root_input"}>
                <span style={{width: "100px", fontSize:"13px"}}>
                  {imageStack.name}
                </span>
                <div className={"div_input"}>
                    <FCInput onChangeHandler={(e) => onImageStackChanged(e)} label={"Ordre d'affichage :"} id={"order"}
                             value={check ? inputOrder : ""} type={"number"} min={-1} max={5} widthInput={"50px"} widthLabel={"125px"}/>
                </div>
            </div>
        </div>
    );
}
export default ImageStack;