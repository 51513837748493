import {SoftSkillInterface} from "./Interfaces/SoftSkillInterface";
import {getToken} from "../../utils/storage/Token";
import {AppSettings} from "../../AppSettings";
import { PostRequest, PutRequest, RequestPromise } from "../../hook/useRequest";

const AddSoftSkills = (id: number, soft: SoftSkillInterface) : RequestPromise => {
    const config = {headers: {Authorization: getToken()}}
    return PostRequest(AppSettings.API_CV + '/userdata/' + id.toString() + '/softskills', soft, config);
}

const UpdateSoftSkills = (id: number, soft: SoftSkillInterface) : RequestPromise=> {
    const config = {headers: {Authorization: getToken()}}
    return PutRequest(AppSettings.API_CV + '/userdata/' + id.toString() + '/softskills/' + soft.softskills_id, soft, config);
}

export {
    AddSoftSkills,
    UpdateSoftSkills
};
