import React, {ReactNode} from 'react';

interface FCSpaceProps {
    size?: number[];
    children: ReactNode;
}

const FCSpace: React.FC<FCSpaceProps> = ({ size = [0, 3], children }) => {

    return (
        <div style={{ display: 'inline-flex', gap: size[0] + "px " + size[1] + "px", flexWrap:"wrap" }}>
            {React.Children.map(children, (child, index) => (
                <React.Fragment key={index}>{child}</React.Fragment>
            ))}
        </div>
    );
};

export default FCSpace;