import {HardSkillInterface} from "./Interfaces/HardSkillInterface";
import {getToken} from "../../utils/storage/Token";
import {AppSettings} from "../../AppSettings";
import { PostRequest, PutRequest, RequestPromise } from "../../hook/useRequest";

const AddHardSkills = (id: number, hard: HardSkillInterface) : RequestPromise => {
    const config = { headers: { Authorization : getToken() }}
    return PostRequest(AppSettings.API_CV + '/userdata/' + id.toString() + '/hardskills', hard, config);
}

const UpdateHardSkills = (id: number, hard: HardSkillInterface) : RequestPromise => {
    const config = { headers: { Authorization : getToken() }}
    return PutRequest(AppSettings.API_CV + '/userdata/' + id.toString() + '/hardskills/' + hard.hardskills_id, hard, config);
}

export {
    AddHardSkills,
    UpdateHardSkills
};
