import React, {useState} from "react";
import {TypeForm} from "../../types/EnumTypeForm";
import ModalCVForm from "./Form/ModalCVForm";
import FCFloatButton, {actionFloatButton} from "../FCObjets/FCFloatButton";
import {
    faDiagramProject,
    faLanguage,
    faLaptopCode,
    faMugHot,
    faPaintBrush,
    faPlus,
    faPrint,
    faSitemap,
    faHome
} from "@fortawesome/free-solid-svg-icons";
import {UserDataInterface} from "../../service/CV/Interfaces/UserDataInterface";

type FloatButtonProps = {
    userData: UserDataInterface;
};

const FloatButtons: React.FunctionComponent<FloatButtonProps> = ({userData}) => {

    const [typeForm, setTypeForm] = useState(TypeForm.EMPTY);

    const items: actionFloatButton[] = [
        {label: "Langage", icon: faLanguage, handlerOnClick: () => setTypeForm(TypeForm.LANGUAGE)},
        {label: "Projet", icon: faHome, handlerOnClick: () => setTypeForm(TypeForm.PROJECT)},
        {label: "Soft skill", icon: faMugHot, handlerOnClick: () => setTypeForm(TypeForm.SOFT_SKILLS)},
        {label: "Hard skill", icon: faSitemap, handlerOnClick: () => setTypeForm(TypeForm.HARD_SKILLS)},
        {label: "Formation", icon: faLaptopCode, handlerOnClick: () => setTypeForm(TypeForm.FORMATION)},
        {label: "Expérience", icon: faDiagramProject, handlerOnClick: () => setTypeForm(TypeForm.EXP)},
    ];

    const handlerPrint = () => {
        let win = window.open(process.env.PUBLIC_URL + "/", '_blank');
        win?.focus();
    }

    /**
     * Clique sur le bouton expérience permettant d'en ajouter
     */
    return (
        <div className={".no-print"}>
            <FCFloatButton items={items} icon={faPlus} positionRight={"100px"}/>
            <FCFloatButton items={[]} icon={faPrint} handlerOnClick={() => handlerPrint()}/>
            <FCFloatButton items={[]} icon={faPaintBrush} positionBottom={"100px"}
                           handlerOnClick={() => setTypeForm(TypeForm.STYLE)}/>

            <ModalCVForm typeForm={typeForm} onModalFormFinished={() => {
                setTypeForm(TypeForm.EMPTY)
            }} data={typeForm === TypeForm.STYLE ? userData.style : undefined} idUserData={userData.user_data_id}/>
        </div>
    );
}

export default FloatButtons;