
import {getToken} from "../utils/storage/Token";
import {AppSettings} from "../AppSettings";
import { DeleteRequest, useGetRequest, RequestResponse, PutRequest, RequestPromise } from "../hook/useRequest";


const DelResetBddCV = (): RequestPromise => {
    const config = { headers: { Authorization : getToken() }}
    return DeleteRequest('/admin' + AppSettings.API_CV + '/resetbdd', config);
}

const UpdateUserInfo = (id_user:number, userInfo: unknown): RequestPromise => {
    const config = { headers: { Authorization : getToken() }}
    return PutRequest(`/admin/config_user/${id_user}`, userInfo, config);
}

const useFetchVersion = (): RequestResponse => {
    return useGetRequest('/admin/version/');
}

export {
    DelResetBddCV,
    UpdateUserInfo,
    useFetchVersion
};
