import React, {useContext, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkedin, faGithub} from "@fortawesome/free-brands-svg-icons";
import {faUmbrellaBeach, faCoffee} from "@fortawesome/free-solid-svg-icons";
import I18nTextNode from "../../I18nTextNode";
import Bezel from "../Design/Bezel";
import {PersonalDataInterface} from "../../../../service/CV/Interfaces/PersonalDataInterface";
import {TypeForm} from "../../../../types/EnumTypeForm";
import ModalCVForm from "../../Form/ModalCVForm";
import {EditableCvContext} from "../../../../store/CV/EditableCvContext";
import ManageStyleCV from "../../../../utils/CV/ManageStyleCV";

type FooterProps = {
    personalData: PersonalDataInterface
};

const Footer: React.FunctionComponent<FooterProps> = ({personalData}) => {
    const [typeForm, setTypeForm] = useState(TypeForm.EMPTY);
    const {editableCV} = useContext(EditableCvContext);

    let icon = faCoffee;
    if (personalData.personalFooterIcon === "faUmbrellaBeach") {
        icon = faUmbrellaBeach
    }

    let colorBackFooter = new ManageStyleCV(personalData?.user_data?.style).getBackgroundFooter();

    return (
        <footer>
            <Bezel height={3} color={colorBackFooter} top={true} right={false} base={1}/>
            <div className={editableCV ? "footer-container hover-form" : "footer-container"}
                onClick={() => editableCV && setTypeForm(TypeForm.FOOTER)}
                style={{backgroundColor:colorBackFooter}}>

                <div className={"hobbies"}>
                    <FontAwesomeIcon
                        className={"icon"}
                        icon={icon}
                    />
                    <p>
                        <I18nTextNode text={personalData.personalFooterNote}/>
                    </p>
                </div>
                <div className={"links"}>
                    {(personalData.user_data.linkedin !== "") && (<a
                        className={"link"}
                        href={personalData.user_data.linkedin}
                        title={"My profile"} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faLinkedin}/>
                    </a>)}
                    {(personalData.user_data.github !== "") && (
                        <a className={"link"} href={personalData.user_data.github} title={"GitHub"} target="_blank"
                           rel="noreferrer">
                            <FontAwesomeIcon icon={faGithub}/>
                        </a>)}
                </div>
            </div>

            <ModalCVForm typeForm={typeForm} onModalFormFinished={() => setTypeForm(TypeForm.EMPTY)} data={personalData}
                         idUserData={personalData.user_data ? personalData.user_data.user_data_id : -1}/>
        </footer>
    );
};

export default Footer;
