import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import I18nTextNode from "../../I18nTextNode";
import {FormationInterface} from "../../../../service/CV/Interfaces/FormationInterface";
import TypeDate from "../../../../types/TypeDate";
import {TypeForm} from "../../../../types/EnumTypeForm";
import ModalCVForm from "../../Form/ModalCVForm";
import {EditableCvContext} from "../../../../store/CV/EditableCvContext";

type FormationProps = {
    data: FormationInterface;
};

const Formation: React.FunctionComponent<FormationProps> = ({data}) => {
    const {t} = useTranslation();
    const [typeForm, setTypeForm] = useState(TypeForm.EMPTY);
    const {editableCV} = useContext(EditableCvContext);

    return (
        <div>
            <div className={editableCV ? "formation hover-form" : "formation"}
                 onClick={() => editableCV && setTypeForm(TypeForm.FORMATION)}>
                <div className={"date"}>
                    {data.end ? (
                        <>
                            {t('from')}
                            {" "}
                            <span className={"from"}>
              <I18nTextNode text={TypeDate.convertDate(data.begin)}/>
            </span>
                            {t('to')}{" "}
                            <span className={"to"}>
              <I18nTextNode text={TypeDate.convertDate(data.end)}/>
            </span>
                        </>
                    ) : (
                        <>
            <span className={"from"}>
              <I18nTextNode text={TypeDate.convertDate(data.begin)}/>
            </span>
                        </>
                    )}
                </div>
                <div className={"form-content"}>
                    <h4 className={"title"}>
                        <I18nTextNode text={data.title}/>
                    </h4>
                    <p className={"secondary"}>
                        <I18nTextNode text={data.secondary}/>
                    </p>
                </div>
            </div>
            <ModalCVForm typeForm={typeForm} onModalFormFinished={() => setTypeForm(TypeForm.EMPTY)} data={data}
                         idUserData={data.user_data ? data.user_data.user_data_id : -1}/>
        </div>
    );
};

export default Formation;
