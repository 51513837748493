import React, {useContext, useState} from "react";
import {EventUpdatedContext} from "../../../../store/EventUpdatedContext";
import FormColorPicker from "./FormColorPicker";
import {UpdateStyle} from "../../../../service/CV/StyleCVService";
import {StyleCVInterface} from "../../../../service/CV/Interfaces/Style/StyleCVInterface";
import FCInput from "../../../FCObjets/Form/FCInput";
import FCDropdown from "../../../FCObjets/Form/FCDropdown";
import FCButton from "../../../FCObjets/FCButton";
import {CallbackErrorModalCV, CallbackFinishedModalCV} from "../ModalCVForm";

type FormStyleListProps = {
    onFinished: CallbackFinishedModalCV;
    onError: CallbackErrorModalCV;
    dataStyle: StyleCVInterface;
    idUserData:number;
};

const FormStyle: React.FunctionComponent<FormStyleListProps> = ({dataStyle, idUserData, onFinished, onError}) => {
    const {setUpdated} = useContext(EventUpdatedContext)

    const [inputStyle, setInputStyle] = useState(dataStyle.title);
    const [inputNumberPage, setInputNumberPage] = useState(dataStyle.number_page);
    const [inputColorTag, setInputColorTag] = useState(dataStyle.colors.tech_tag);
    const [colorHeader, setColorHeader] = useState(dataStyle.colors.header);
    const [colorFooter, setColorFooter] = useState(dataStyle.colors.footer);
    const [colorSkills, setColorSkills] = useState(dataStyle.colors.skills);

    const options = [
        { value: "blue", label: "Bleu" },
        { value: "red", label: "Rouge" },
        { value: "green", label: "Green" },
        { value: "yellow", label: "Yellow" },
        { value: "purple", label: "Violet" },
        { value: "geekblue", label: "GeekBlue" },
        { value: "orange", label: "Orange" }
    ];
    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        onError(null);

        let newStyle: StyleCVInterface = {
            colors: {
                color_id:dataStyle.colors.color_id,
                skills: colorSkills,
                header: colorHeader,
                footer: colorFooter,
                tech_tag: inputColorTag,
            },
            number_page: inputNumberPage,
            style_cv_id: dataStyle.style_cv_id,
            title: inputStyle
        };

        UpdateStyle(idUserData, newStyle).then(() => {
            setUpdated();
            onFinished();
        }).catch((err) => onError(err));
    }

    return (
        <form>
            <div>
                <FCInput onChangeHandler={(e) => setInputStyle(e.target.value)} label={"Nom du style :"} id={"style"}
                         value={dataStyle.title} widthInput={"500px"} widthLabel={"200px"}/>
                <FCInput onChangeHandler={(e) => setInputNumberPage(parseInt(e.target.value))} label={"Nombre de page :"} id={"page"}
                         value={dataStyle.number_page} type={"number"} min={1} max={2} widthLabel={"200px"}/>
                <FCDropdown onChangeHandler={(e) => setInputColorTag(e.target.value)} options={options}
                            label={"Couleur des tags Tech :"} id={"Color_tech"} value={dataStyle.colors.tech_tag}
                            widthDropdown={"500px"} widthLabel={"200px"}/>

                <div style={{display: "flex", flexDirection: "row", marginBottom: "20px", marginTop: "20px"}}>
                    <FormColorPicker name={"Fond En-tête"} onChange={(e) => setColorHeader(e)}
                                     colorInit={dataStyle.colors.header}/>
                    <FormColorPicker name={"Fond Pied de page"} onChange={(e) => setColorFooter(e)}
                                     colorInit={dataStyle.colors.footer}/>
                    <FormColorPicker name={"Fond des skills"} onChange={(e) => setColorSkills(e)}
                                     colorInit={dataStyle.colors.skills}/>
                </div>
            </div>
            <div style={{textAlign: "right"}}>
                <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit}/>
            </div>
        </form>
    );
}

export default FormStyle;