import React, {useContext, useState} from "react";
import {EventUpdatedContext} from "../../../store/EventUpdatedContext";
import {PersonalDataInterface} from "../../../service/CV/Interfaces/PersonalDataInterface";
import {UpdatePersonalData} from "../../../service/CV/PersonalDataService";
import {UpdateUserData} from "../../../service/CV/UserDataService";
import FCInput from "../../FCObjets/Form/FCInput";
import FCButton from "../../FCObjets/FCButton";
import {CallbackErrorModalCV, CallbackFinishedModalCV} from "./ModalCVForm";

type FormFooterListProps = {
    onFinished: CallbackFinishedModalCV;
    onError: CallbackErrorModalCV;
    dataPersonal: PersonalDataInterface | undefined;
    idUserData: number;
};

const FormFooter: React.FunctionComponent<FormFooterListProps> = ({dataPersonal, idUserData, onFinished, onError}) => {
    const {setUpdated} = useContext(EventUpdatedContext)

    const [inputPersonalFooterNote, setInputPersonalFooterNote] = useState(dataPersonal ? dataPersonal.personalFooterNote : "");
    const [inputLinkedin, setInputLinkedin] = useState(dataPersonal ? dataPersonal.user_data.linkedin : "");
    const [inputGithub, setInputGihub] = useState(dataPersonal ? dataPersonal.user_data.github : "");
    const [inputPersonalFooterIcon, setInputPersonalFooterIcon] = useState(dataPersonal ? dataPersonal.personalFooterIcon : "");

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        onError(null);

        let pers: PersonalDataInterface;
        pers = {
            lang: "",
            license: dataPersonal ? dataPersonal.license : "",
            personalFooterIcon: inputPersonalFooterIcon,
            personalFooterNote: inputPersonalFooterNote,
            personal_data_id: dataPersonal ? dataPersonal.personal_data_id : -1,
            title: dataPersonal ? dataPersonal.title : "",
            user_data: {
                user_data_id: dataPersonal ? dataPersonal.user_data.user_data_id : -1,
                phone: dataPersonal ? dataPersonal.user_data.phone : "",
                target_phone: dataPersonal ? dataPersonal.user_data.target_phone : "",
                mail: dataPersonal ? dataPersonal.user_data.mail : "",
                target_mail: dataPersonal ? dataPersonal.user_data.target_mail : "",
                age: dataPersonal ? dataPersonal.user_data.age : -1,
                linkedin: inputLinkedin,
                github: inputGithub,
                style: dataPersonal?.user_data.style,
                userCnx: {
                    firstname:dataPersonal ? dataPersonal.user_data.userCnx.firstname : "",
                    lastname:dataPersonal ? dataPersonal.user_data.userCnx.lastname : "",
                    username:dataPersonal ? dataPersonal.user_data.userCnx.username : "",
                    id:dataPersonal ? dataPersonal.user_data.userCnx.id : -1,
                }
            }
        };

        if(dataPersonal) {
            UpdateUserData(pers.user_data).then(() => {
                UpdatePersonalData(idUserData, pers).then(() => {
                    setUpdated();
                    onFinished();
                }).catch((err) => onError(err));
            }).catch((err) => onError(err));
        }
    }

    return (
        <form>
            <div>
                <FCInput onChangeHandler={(e) => setInputPersonalFooterNote(e.target.value)} label={"Note :"} id={"note"}
                         value={inputPersonalFooterNote}/>
                <FCInput onChangeHandler={(e) => setInputPersonalFooterIcon(e.target.value)} label={"Nom icône :"} id={"nom_icone"}
                         value={inputPersonalFooterIcon}/>
                <FCInput onChangeHandler={(e) => setInputLinkedin(e.target.value)} label={"Linkedin :"} id={"linkedin"}
                         value={inputLinkedin}/>
                <FCInput onChangeHandler={(e) => setInputGihub(e.target.value)} label={"Github :"} id={"github"}
                         value={inputGithub} />
            </div>
            <div style={{textAlign: "right"}}>
                <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit}/>
            </div>
        </form>
    );
}

export default FormFooter;