import {StyleCVInterface} from "../../service/CV/Interfaces/Style/StyleCVInterface";

class ManageStyleCV {

    private m_styleCV : StyleCVInterface | undefined;

    constructor(style? : StyleCVInterface) {
        this.m_styleCV = style;
    }

    public getBackgroundHeader() : string {
        if(this.m_styleCV === undefined) {
            return "#2b333c";
        }

        return this.m_styleCV.colors.header;
    }

    public getBackgroundFooter() : string {
        if(this.m_styleCV === undefined) {
            return "#2b333c";
        }

        return this.m_styleCV.colors.footer;
    }

    public getBackgroundSkills() : string {
        if(this.m_styleCV === undefined) {
            return "#bfdaf0";
        }

        return this.m_styleCV.colors.skills;
    }

    public getColorTagTechExp() : string {
        if(this.m_styleCV === undefined) {
            return "blue";
        }

        return this.m_styleCV.colors.tech_tag;
    }


}

export default ManageStyleCV;