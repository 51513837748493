import React, {useContext, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobeEurope} from "@fortawesome/free-solid-svg-icons";
import {LangInterface} from "../../../../service/CV/Interfaces/LangInterface";
import ModalCVForm from "../../Form/ModalCVForm";
import {TypeForm} from "../../../../types/EnumTypeForm";
import {EditableCvContext} from "../../../../store/CV/EditableCvContext";

type LangProps = {
    lang: LangInterface;
};

const Lang: React.FunctionComponent<LangProps> = ({lang}) => {

    const [typeForm, setTypeForm] = useState(TypeForm.EMPTY);
    const {editableCV} = useContext(EditableCvContext);

    return (
        <div>
            <div className={editableCV ? "lang hover-form" : "lang"}
                 onClick={() => editableCV && setTypeForm(TypeForm.LANGUAGE)}>
                <div>
                    <FontAwesomeIcon
                        fixedWidth={true}
                        className={"icon"}
                        icon={faGlobeEurope}
                    />
                    <span>
                    {lang.name + " niveau " + lang.level}
                  </span>
                </div>
            </div>
            <ModalCVForm typeForm={typeForm} onModalFormFinished={() => setTypeForm(TypeForm.EMPTY)} data={lang}
                         idUserData={lang.user_data ? lang.user_data.user_data_id : -1}/>
        </div>
    );
};

export default Lang;
