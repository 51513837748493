import { ResourceLanguage } from "i18next";

export const fr: ResourceLanguage = {
  translation: {
    print: "Imprimer",
    print_this_page: "cette page pour avoir un obtenir mon CV au format PDF.",
    from: "De",
    to: "à",
    since: "Depuis",
    techs_headers: "Technologies",
    experiences: "Expériences",
    exp_year: "an",
    exp_years: "ans",
    header_side_projets: "Mes projets perso",
    header_skills: "Compétences clefs",
    header_soft_skills: "Soft skills",
    contact_me: "Contactez moi !",
    contact_part_1:
      "Ces informations sont masquées pour "  +
      "éviter d'être récupérées par des robots.",
    contact_part_2: "Cliquez ici pour les afficher.",
  },
};
