import {LangInterface} from "./Interfaces/LangInterface";
import {getToken} from "../../utils/storage/Token";
import {AppSettings} from "../../AppSettings";
import { PostRequest, PutRequest, RequestPromise } from "../../hook/useRequest";

const AddLanguage = (id: number, lang: LangInterface) : RequestPromise=> {
    const config = { headers: { Authorization : getToken() }}
    return PostRequest(AppSettings.API_CV + '/userdata/' + id.toString() + '/lang', lang, config);
}

const UpdateLanguage = (id: number, lang: LangInterface) : RequestPromise => {
    const config = { headers: { Authorization : getToken() }}
    return PutRequest(AppSettings.API_CV + '/userdata/' + id.toString() + '/lang/' + lang.lang_id, lang, config);
}

export {
    AddLanguage,
    UpdateLanguage
};
