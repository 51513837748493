import {PersonalDataInterface} from "./Interfaces/PersonalDataInterface";
import {getToken} from "../../utils/storage/Token";
import {AppSettings} from "../../AppSettings";
import { PutRequest, RequestPromise } from "../../hook/useRequest";

const UpdatePersonalData = (id: number, pers: PersonalDataInterface) : RequestPromise => {
    const config = {headers: {Authorization: getToken()}}
    return PutRequest(AppSettings.API_CV + '/userdata/' + id.toString() + '/personalData/' + pers.personal_data_id, pers, config);
}

export {
    UpdatePersonalData
};
