import React, {useContext, useState} from "react";
import {ProjectInterface} from "../../../../service/CV/Interfaces/Project";
import {AppSettings} from "../../../../AppSettings";
import {EditableCvContext} from "../../../../store/CV/EditableCvContext";
import {TypeForm} from "../../../../types/EnumTypeForm";
import ModalCVForm from "../../Form/ModalCVForm";

type SideProjectProps = {
    project: ProjectInterface;
};

const SideProject: React.FunctionComponent<SideProjectProps> = ({
                                                                    project,
                                                                }) => {

    const {editableCV} = useContext(EditableCvContext);
    const [typeForm, setTypeForm] = useState(TypeForm.EMPTY);

    return (

        <div className={editableCV ? "hover-form" : ""}
             onClick={() => editableCV && setTypeForm(TypeForm.PROJECT)}>
            <a style={editableCV ? {pointerEvents:"none"} : {}} className={"projet"} href={project.link} target={"_blank"}>
                <div className={"images"}>
                    <img className={"project-img"} alt={project.titre} key={project.images}
                         src={AppSettings.API_ENDPOINT + `/images/projects/` + project.images}/>
                </div>
                <div className={"proj-desc"}>
                    <span className={"proj-title"}>{project.titre}</span>
                    <p className={"secondary"}>{project.shortDescription}</p>
                </div>
            </a>
            <ModalCVForm typeForm={typeForm} onModalFormFinished={() => setTypeForm(TypeForm.EMPTY)}
                         data={project}
                         idUserData={project.user_data ? project.user_data.user_data_id : -1}/>
        </div>
    );
};

export default SideProject;
