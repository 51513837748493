import React, {useContext, useState} from "react";
import {EventUpdatedContext} from "../../../store/EventUpdatedContext";
import {HardSkillInterface} from "../../../service/CV/Interfaces/HardSkillInterface";
import {AddHardSkills, UpdateHardSkills} from "../../../service/CV/HardSkillsService";
import FCInput from "../../FCObjets/Form/FCInput";
import FCButton from "../../FCObjets/FCButton";
import {CallbackErrorModalCV, CallbackFinishedModalCV} from "./ModalCVForm";

type FormSkillListProps = {
    onFinished: CallbackFinishedModalCV;
    onError: CallbackErrorModalCV;
    dataSkills: HardSkillInterface | undefined;
    idUserData: number;
};

const FormHardSkill: React.FunctionComponent<FormSkillListProps> = ({dataSkills, idUserData, onFinished, onError}) => {
    const {setUpdated} = useContext(EventUpdatedContext)

    const [inputNom, setInputNom] = useState(dataSkills ? dataSkills.name : "");
    const [inputNumberExp, setInputNumberExp] = useState(dataSkills ? dataSkills.experience : 0);
    const [inputPercent, setInputPercent] = useState(dataSkills ? dataSkills.percentage : 0);

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        onError(null);

        let skill: HardSkillInterface = {
            experience: inputNumberExp,
            hardskills_id: dataSkills ? dataSkills.hardskills_id : -1,
            lang: "fr",
            name: inputNom,
            percentage: inputPercent,
            user_data: undefined
        };

        if (dataSkills) {
            UpdateHardSkills(idUserData, skill).then(() => {
                setUpdated();
                onFinished();
            }).catch((err) => onError(err));
        } else {
            AddHardSkills(idUserData, skill).then(() => {
                setUpdated();
                onFinished();
            }).catch((err) => onError(err));
        }
    }

    return (
        <form>
            <div>
                <FCInput onChangeHandler={(e) => setInputNom(e.target.value)} label={"Nom :"} id={"Nom"}
                         value={inputNom}/>
                <FCInput onChangeHandler={(e) => setInputNumberExp(parseInt(e.target.value))} label={"Expérience :"}
                         id={"XP"}
                         value={inputNumberExp} type={"number"}/>
                <FCInput onChangeHandler={(e) => setInputPercent(parseInt(e.target.value))} label={"Pourcentage :"}
                         id={"Percent"}
                         value={inputPercent} type={"number"}/>
            </div>
            <div style={{textAlign: "right"}}>
                <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit}/>
            </div>
        </form>
    );
}

export default FormHardSkill;