import React, {useState} from "react";
import {TypeForm} from "../../../types/EnumTypeForm";
import FormExperience from "./FormExperience";
import FormSoftSkill from "./FormSoftSkill";
import FormHardSkill from "./FormHardSkill";
import FormMyStack from "./FormStack/FormMyStack";
import FormFormation from "./FormFormation";
import FormLanguage from "./FormLanguage";
import FormHeader from "./FormHeader";
import FormFooter from "./FormFooter";
import FormStyle from "./FormStyle/FormStyle";
import FCModal from "../../FCObjets/FCModal";
import FCAlertMsgErrorAPI from "../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import FormProject from "./FormProject";

export type CallbackFinishedModalCV = () => void;
export type CallbackErrorModalCV = (error: any) => void;

type TypeModalForm = {
    onModalFormFinished: CallbackFinishedModalCV;
    typeForm: TypeForm;
    data: any | undefined;
    idUserData: number;
}

const ModalCVForm: React.FunctionComponent<TypeModalForm> = ({typeForm, data, idUserData, onModalFormFinished}) => {

    const [error, setError] = useState<any>(null);

    function onFinished() {
        onModalFormFinished();
    }

    function onError(err: any) {
        setError(err);
    }

    return (
        <FCModal isOpen={typeForm !== TypeForm.EMPTY} onClose={onFinished} width={
            ((typeForm === TypeForm.EXP || typeForm === TypeForm.MYSTACK) && "850px") ||
            (typeForm === TypeForm.FOOTER && "600px") ||
            (typeForm === TypeForm.STYLE && "800px") || "450px"}
                 title={
                     (typeForm === TypeForm.EXP && "Expérience") ||
                     (typeForm === TypeForm.HARD_SKILLS && "Hard Skill") ||
                     (typeForm === TypeForm.SOFT_SKILLS && "Soft Skill") ||
                     (typeForm === TypeForm.MYSTACK && "My stack") ||
                     (typeForm === TypeForm.FORMATION && "Formation") ||
                     (typeForm === TypeForm.LANGUAGE && "Langue") ||
                     (typeForm === TypeForm.HEADER && "En-tête") ||
                     (typeForm === TypeForm.FOOTER && "Pied de page") ||
                     (typeForm === TypeForm.STYLE && "Gestion du style") ||
                     (typeForm === TypeForm.PROJECT && "Projet") || undefined}>
            {
                (typeForm === TypeForm.EXP &&
                    <FormExperience onFinished={onFinished} dataExperience={data} idUserData={idUserData}
                                    onError={onError}/>) ||
                (typeForm === TypeForm.HARD_SKILLS &&
                    <FormHardSkill onFinished={onFinished} dataSkills={data} idUserData={idUserData}
                                   onError={onError}/>) ||
                (typeForm === TypeForm.SOFT_SKILLS &&
                    <FormSoftSkill onFinished={onFinished} dataSkills={data} idUserData={idUserData}
                                   onError={onError}/>) ||
                (typeForm === TypeForm.MYSTACK &&
                    <FormMyStack onFinished={onFinished} dataStack={data} idUserData={idUserData}
                                 onError={onError}/>) ||
                (typeForm === TypeForm.FORMATION &&
                    <FormFormation onFinished={onFinished} dataFormation={data} idUserData={idUserData}
                                   onError={onError}/>) ||
                (typeForm === TypeForm.LANGUAGE &&
                    <FormLanguage onFinished={onFinished} dataLanguage={data} idUserData={idUserData}
                                  onError={onError}/>) ||
                (typeForm === TypeForm.HEADER &&
                    <FormHeader onFinished={onFinished} dataPersonal={data} idUserData={idUserData}
                                onError={onError}/>) ||
                (typeForm === TypeForm.FOOTER &&
                    <FormFooter onFinished={onFinished} dataPersonal={data} idUserData={idUserData}
                                onError={onError}/>) ||
                (typeForm === TypeForm.STYLE &&
                    <FormStyle onFinished={onFinished} dataStyle={data} idUserData={idUserData} onError={onError}/>) ||
                (typeForm === TypeForm.PROJECT &&
                    <FormProject onFinished={onFinished} dataProject={data} idUserData={idUserData} onError={onError}/>)
            }
            {error && <FCAlertMsgErrorAPI error={error} activate={!!error}/>}
        </FCModal>
    );
}

export default ModalCVForm;