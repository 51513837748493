import React, {useContext, useState} from "react";
import {EventUpdatedContext} from "../../../store/EventUpdatedContext";
import {FormationInterface} from "../../../service/CV/Interfaces/FormationInterface";
import {AddFormation, UpdateFormation} from "../../../service/CV/FormationService";
import FCInput from "../../FCObjets/Form/FCInput";
import FCButton from "../../FCObjets/FCButton";
import FCDatePicker from "../../FCObjets/Form/FCDatePicker";
import FCTextArea from "../../FCObjets/Form/FCTextArea";
import {CallbackErrorModalCV, CallbackFinishedModalCV} from "./ModalCVForm";

type FormFormationListProps = {
    onFinished: CallbackFinishedModalCV;
    onError: CallbackErrorModalCV;
    dataFormation: FormationInterface | undefined;
    idUserData: number;
};

const FormFormation: React.FunctionComponent<FormFormationListProps> = ({dataFormation, idUserData, onFinished, onError}) => {
    const {setUpdated} = useContext(EventUpdatedContext)

    const [inputTitle, setInputTitle] = useState(dataFormation ? dataFormation.title : "");
    const [inputLoc, setInputLoc] = useState(dataFormation ? dataFormation.secondary : "");
    const [dateBegin, setDateBegin] = useState(dataFormation ? new Date(dataFormation.begin) : null);
    const [dateEnd, setDateEnd]
        = useState((dataFormation &&
        dataFormation.end !== null && dataFormation.end !== undefined && dataFormation.end !== "") ? new Date(dataFormation.end) : null);

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        onError(null);

        let formation: FormationInterface = {
            begin: dateBegin ? dateBegin.toDateString() : "",
            end: dateEnd ? dateEnd.toDateString() : "",
            formation_id: dataFormation ? dataFormation.formation_id : -1,
            lang: "fr",
            secondary: inputLoc,
            title: inputTitle,
            user_data: undefined
        };

        if (dataFormation) {
            UpdateFormation(idUserData, formation).then(() => {
                setUpdated();
                onFinished();
            }).catch((err) => onError(err));
        } else {
            AddFormation(idUserData, formation).then(() => {
                setUpdated();
                onFinished();
            }).catch((err) => onError(err));
        }
    }

    return (
        <form>
            <div>
                <FCTextArea onChangeHandler={(e) => setInputTitle(e.target.value)} label={"Nom formation :"}
                            id={"titre"}
                            value={inputTitle} row={4}/>
                <FCInput onChangeHandler={(e) => setInputLoc(e.target.value)} label={"Formation :"} id={"form"}
                         value={inputLoc} widthInput={"300px"}/>
                <FCDatePicker onChangeHandler={setDateBegin} value={dateBegin} id={"deb"} label={"Début"}/>
                <FCDatePicker onChangeHandler={setDateEnd} value={dateEnd} id={"fin"} label={"Fin"}/>
            </div>
            <div style={{textAlign: "right"}}>
                <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit}/>
            </div>
        </form>

        );
}

export default FormFormation;