import {faGlobeEurope} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useTranslation} from "react-i18next";
import {useI18nTextOpener} from "../../../../hook/useI18nText";
import Section from "../Elements/Section";
import {DisplayLevel} from "../../../../types/CV/EnumDisplayLevel";
import Experience from "../Elements/Experience";
import {ExperienceInterface} from "../../../../service/CV/Interfaces/ExperienceInterface";

type ExperiencesListProps = {
    dataExperiences: ExperienceInterface[];
};

const ExperiencesList: React.FunctionComponent<ExperiencesListProps> = ({dataExperiences}) => {

    const i18nOpener = useI18nTextOpener();
    const {t} = useTranslation();

    return (
        <Section
            className={"experiences"}
            title={t<string>("experiences")}
            icon={faGlobeEurope}>
            {dataExperiences.map((e) =>
                e.displayLevel === DisplayLevel.NONE ? null : (
                    <Experience data={e} key={i18nOpener(e.experience_id.toString())}/>
                )
            )}
        </Section>
    );
};

export default ExperiencesList;
