class TypeDate {

    private static months: string[] = ["Jan.", "Fév.", "Mars", "Avril", "Mai", "Juin", "Juil.", "Août", "Sep.", "Oct.", "Nov.", "Déc."];
    static monthsLong: string[] = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

    static convertDate(date: string) : string{
        const d : Date = new Date(date)
        const month_str : string = (TypeDate.months)[d.getMonth()]
        const year_str : string = d.getFullYear().toString();
        return month_str + " " + year_str;
    }
}

export default TypeDate;