import React, {useContext, useState} from "react";
import {EventUpdatedContext} from "../../../store/EventUpdatedContext";
import {PersonalDataInterface} from "../../../service/CV/Interfaces/PersonalDataInterface";
import {UpdatePersonalData} from "../../../service/CV/PersonalDataService";
import {UpdateUserData} from "../../../service/CV/UserDataService";
import FCInput from "../../FCObjets/Form/FCInput";
import FCButton from "../../FCObjets/FCButton";
import {CallbackErrorModalCV, CallbackFinishedModalCV} from "./ModalCVForm";

type FormHeaderListProps = {
    onFinished: CallbackFinishedModalCV;
    onError: CallbackErrorModalCV;
    dataPersonal: PersonalDataInterface | undefined;
    idUserData: number;
};

const FormHeader: React.FunctionComponent<FormHeaderListProps> = ({dataPersonal, idUserData, onFinished, onError}) => {
    const {setUpdated} = useContext(EventUpdatedContext)

    const [inputLastName, setInputLastName] = useState(dataPersonal ? dataPersonal.user_data.userCnx.lastname : "");
    const [inputFirstName, setInputFirstName] = useState(dataPersonal ? dataPersonal.user_data.userCnx.firstname : "");
    const [inputTitle, setInputTitle] = useState(dataPersonal ? dataPersonal.title : "");
    const [inputMail, setInputMail] = useState(dataPersonal ? dataPersonal.user_data.mail : "");
    const [inputPhone, setInputPhone] = useState(dataPersonal ? dataPersonal.user_data.phone : "");
    const [inputLicence, setInputLicence] = useState(dataPersonal ? dataPersonal.license : "");

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {

        onError(null);
        let pers: PersonalDataInterface;
        pers = {
            lang: "",
            license: inputLicence,
            personalFooterIcon: "",
            personalFooterNote: "",
            personal_data_id: dataPersonal ? dataPersonal.personal_data_id : -1,
            title: inputTitle,
            user_data: {
                user_data_id: dataPersonal ? dataPersonal.user_data.user_data_id : -1,
                phone: inputPhone,
                target_phone: "phone://" + inputPhone,
                mail: inputMail,
                target_mail: "mail://" + inputMail,
                age: dataPersonal ? dataPersonal.user_data.age : -1,
                linkedin: dataPersonal ? dataPersonal.user_data.linkedin : "",
                github: dataPersonal ? dataPersonal.user_data.github : "",
                style: dataPersonal?.user_data.style,
                userCnx: {
                    firstname: inputFirstName,
                    lastname: inputLastName,
                    username: dataPersonal ? dataPersonal.user_data.userCnx.username : "",
                    id: dataPersonal ? dataPersonal.user_data.userCnx.id : -1,
                }
            }
        };

        if (dataPersonal) {
            UpdatePersonalData(idUserData, pers).then(() => {
                UpdateUserData(pers.user_data).then(() => {
                    setUpdated();
                    onFinished();
                }).catch((err) => onError(err));
            }).catch((err) => onError(err));
        }
    }

    return (
        <form>
            <div>
                <FCInput onChangeHandler={(e) => setInputFirstName(e.target.value)} label={"Prénom :"} id={"prenom"}
                         value={inputFirstName}/>
                <FCInput onChangeHandler={(e) => setInputLastName(e.target.value)} label={"Nom :"} id={"nom"}
                         value={inputLastName}/>
                <FCInput onChangeHandler={(e) => setInputTitle(e.target.value)} label={"Titre :"} id={"titre"}
                         value={inputTitle}/>
                <FCInput onChangeHandler={(e) => setInputMail(e.target.value)} label={"Mail :"} id={"mail"}
                         value={inputMail}/>
                <FCInput onChangeHandler={(e) => setInputPhone(e.target.value)} label={"Phone :"} id={"phone"}
                         value={inputPhone}/>
                <FCInput onChangeHandler={(e) => setInputLicence(e.target.value)} label={"Permis :"} id={"licence"}
                         value={inputLicence}/>
            </div>
            <div style={{textAlign: "right"}}>
                <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit}/>
            </div>
        </form>
    );
}

export default FormHeader;