import React, {useState} from "react";
import {ChromePicker} from "react-color";
import "./FormColorPicker.scss"

type CallBackOnChnage = (hex:string) => void;

type FormColorPickerListProps = {
    name: string;
    colorInit:string
    onChange:CallBackOnChnage;
};

const FormColorPicker: React.FunctionComponent<FormColorPickerListProps> = ({name, colorInit, onChange}) => {

    const [color, setColor] = useState({
        r: '241',
        g: '112',
        b: '19',
        a: '1',
        hex: colorInit,
    })

    const handleChange = (color: any) => {
        let c = {
            r: color.rgb.r,
            g: color.rgb.g,
            b: color.rgb.b,
            a: color.rgb.a,
            hex: color.hex
        };
        setColor(c);
        onChange(color.hex);
    };

    return (
        <div style={{width:"225px", marginRight:"10px"}}>
            <div className={"divFormColorPicker"}>
                {name}
            </div>

            <div className={"popoverFormColorPicker"} >
                <ChromePicker disableAlpha={true} color={color.hex} onChange={handleChange}/>
                <div className={"swatchFormColorPicker"} >
                    <div className={"colorFormColorPicker"} style={{backgroundColor:color.hex}} />
                </div>
            </div>

        </div>
    );
}

export default FormColorPicker;