import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import I18nTextNode from "../../I18nTextNode";
import {HardSkillInterface} from "../../../../service/CV/Interfaces/HardSkillInterface";
import {TypeForm} from "../../../../types/EnumTypeForm";
import ModalCVForm from "../../Form/ModalCVForm";
import {SoftSkillInterface} from "../../../../service/CV/Interfaces/SoftSkillInterface";
import {EditableCvContext} from "../../../../store/CV/EditableCvContext";

type SkillProps = {
    skill: HardSkillInterface | SoftSkillInterface;
    typeSkill: TypeForm;
};

const Skill: React.FunctionComponent<SkillProps> = ({skill, typeSkill}) => {
    const {t} = useTranslation();
    const [typeForm, setTypeForm] = useState(TypeForm.EMPTY);
    const {editableCV} = useContext(EditableCvContext);

    let progress = <div></div>;

    if ("percentage" in skill && (skill.percentage !== 0 && skill.percentage)) {
        progress = <div className={"progress-bar-container"}>
            <div
                className={"progress-bar"}
                style={{width: `${skill.percentage}%`}}/>
        </div>;
    }

    let xp = <span/>;
    if ("experience" in skill) {
        xp = <span className={"xp"}>{skill.experience} {t("exp_years")}</span>;

        if (skill.experience === 0 || !skill.experience) {
            xp = <span/>;
        } else if (skill.experience === 1) {
            xp = <span className={"xp"}>{"experience" in skill && skill.experience} {t("exp_year")}</span>;
        }
    }

    return (
        <div className={"skill"}>
            <div className={editableCV ? "hover-form" : ""} onClick={() => editableCV && setTypeForm(typeSkill)}>
                <div className={"meta"}>
                    <div className={"title"}>
                        <I18nTextNode text={skill.name}/>
                    </div>
                    {xp}
                </div>
                {progress}
            </div>
            <ModalCVForm typeForm={typeForm} onModalFormFinished={() => setTypeForm(TypeForm.EMPTY)} data={skill}
                         idUserData={skill.user_data ? skill.user_data.user_data_id : -1}/>
        </div>
    );
};

export default Skill;
