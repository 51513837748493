import { useGetRequest, RequestResponse } from "../../hook/useRequest";
import {AppSettings} from "../../AppSettings";

const useFetchCV = (firstname: string, lastname: string, forceUpdated: number): RequestResponse => {
    return useGetRequest(AppSettings.API_CV + '/getcv/' + firstname + '&' + lastname, forceUpdated);
}

export {
    useFetchCV
};
