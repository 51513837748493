import {getToken} from "../../utils/storage/Token";
import {AppSettings} from "../../AppSettings";
import { PostRequest, PutRequest, RequestPromise } from "../../hook/useRequest";
import {ProjectInterface} from "./Interfaces/Project";

const AddProject = (id: number, project: ProjectInterface) : RequestPromise => {
    const config = {headers: {Authorization: getToken()}}
    return PostRequest(AppSettings.API_CV + '/userdata/' + id.toString() + '/project', project, config);
}

const UpdateProject = (id: number, project: ProjectInterface) : RequestPromise=> {
    const config = {headers: {Authorization: getToken()}}
    return PutRequest(AppSettings.API_CV + '/userdata/' + id.toString() + '/project/' + project.project_id, project, config);
}

export {
    AddProject,
    UpdateProject
};
