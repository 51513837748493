import {useEffect, useState} from "react";
import axios, {AxiosPromise} from "axios";
import {AppSettings} from "../AppSettings";

axios.defaults.baseURL = AppSettings.API_ENDPOINT;

export const enum TypeRequest {
    GET = "get",
    POST = "post",
    PUT = "put",
    DELETE = "delete",
}

export interface RequestResponse {
    data: any,
    loading: boolean,
    error:any
}

//Si demain on change de bibliothèque de requête on peut facilement en remplaçant le type ici
export type RequestPromise = AxiosPromise;

/**
 * Cette fonction est un hook personnalisé qui permet de faire une requête HTTP avec Axios à partir d'une URL donnée.
 * Elle retourne un objet contenant les données de la réponse de la requête, un booléen pour indiquer si la requête est en cours et une éventuelle erreur.
 *
 * @param type Le type de la requête HTTP : TypeRequest.GET, TypeRequest.POST, TypeRequest.PUT ou TypeRequest.DELETE.
 * @param url L'URL de la ressource à récupérer ou à modifier.
 * @param forceUpdated (Optionnel) Un entier pour forcer la mise à jour du hook.
 * @param payload (Optionnel) Les données à envoyer avec la requête HTTP.
 * @param config (Optionnel) La configuration pour la requête HTTP.
 * @returns Un objet contenant les données de la réponse de la requête, un booléen pour indiquer si la requête est en cours et une éventuelle erreur.
 */
function useAbstractRequest(type: TypeRequest, url: string, forceUpdated = 0, payload = {}, config = {}): RequestResponse {
    // Initialisation des variables d'état pour les données, le chargement et les erreurs.
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [conf] = useState(config);

    // Utilisation d'useEffect pour effectuer la requête HTTP.
    useEffect(() => {
        // Réinitialisation des variables d'état.
        setLoading(true);
        setError(null);
        setData(null);
        // Appel de la fonction AbstractRequest avec les paramètres passés à useAbstractRequest.
        AbstractRequest(type, url, payload, config).then((res) => {
            setLoading(false);
            setData(res.data);
            setError(null);
        }).catch((err) => {
            setLoading(false);
            setError(err);
        })
    }, [url, forceUpdated, conf]);

    return {data, loading, error}
}

/**
 * Cette fonction est un hook personnalisé qui permet de faire une requête HTTP GET avec Axios à partir d'une URL donnée.
 * Elle retourne un objet contenant les données de la réponse de la requête, un booléen pour indiquer si la requête est en cours et une éventuelle erreur.
 *
 * @param url L'URL de la ressource à récupérer ou à modifier.
 * @param forceUpdated (Optionnel) Un entier pour forcer la mise à jour du hook.
 * @param config (Optionnel) La configuration pour la requête HTTP.
 * @returns Un objet contenant les données de la réponse de la requête, un booléen pour indiquer si la requête est en cours et une éventuelle erreur.
 */
function useGetRequest(url: string, forceUpdated = 0, config = {}): RequestResponse {
    return useAbstractRequest(TypeRequest.GET, url, forceUpdated, {}, config);
}

/**
 * Cette fonction est un hook personnalisé qui permet de faire une requête HTTP DELETE avec Axios à partir d'une URL donnée.
 * Elle retourne un objet contenant les données de la réponse de la requête, un booléen pour indiquer si la requête est en cours et une éventuelle erreur.
 *
 * @param url L'URL de la ressource à récupérer ou à modifier.
 * @param forceUpdated (Optionnel) Un entier pour forcer la mise à jour du hook.
 * @param config (Optionnel) La configuration pour la requête HTTP.
 * @returns Un objet contenant les données de la réponse de la requête, un booléen pour indiquer si la requête est en cours et une éventuelle erreur.
 */
function useDeleteRequest(url: string, forceUpdated = 0, config = {}): RequestResponse {
    return useAbstractRequest(TypeRequest.DELETE, url, forceUpdated, {}, config);
}

/**
 * Cette fonction est un hook personnalisé qui permet de faire une requête HTTP POST avec Axios à partir d'une URL donnée.
 * Elle retourne un objet contenant les données de la réponse de la requête, un booléen pour indiquer si la requête est en cours et une éventuelle erreur.
 *
 * @param url L'URL de la ressource à récupérer ou à modifier.
 * @param forceUpdated (Optionnel) Un entier pour forcer la mise à jour du hook.
 * @param payload (Optionnel) Les données à envoyer avec la requête HTTP.
 * @param config (Optionnel) La configuration pour la requête HTTP.
 * @returns Un objet contenant les données de la réponse de la requête, un booléen pour indiquer si la requête est en cours et une éventuelle erreur.
 */
function usePostRequest(url: string, forceUpdated = 0, payload = {}, config = {}): RequestResponse {
    return useAbstractRequest(TypeRequest.POST, url, forceUpdated, payload, config);
}

/**
 * Cette fonction est un hook personnalisé qui permet de faire une requête HTTP PUT avec Axios à partir d'une URL donnée.
 * Elle retourne un objet contenant les données de la réponse de la requête, un booléen pour indiquer si la requête est en cours et une éventuelle erreur.
 *
 * @param url L'URL de la ressource à récupérer ou à modifier.
 * @param forceUpdated (Optionnel) Un entier pour forcer la mise à jour du hook.
 * @param payload (Optionnel) Les données à envoyer avec la requête HTTP.
 * @param config (Optionnel) La configuration pour la requête HTTP.
 * @returns Un objet contenant les données de la réponse de la requête, un booléen pour indiquer si la requête est en cours et une éventuelle erreur.
 */
function usePutRequest(url: string, forceUpdated = 0, payload: any = {}, config: any = {}): RequestResponse {
    return useAbstractRequest(TypeRequest.PUT, url, forceUpdated, payload, config);
}


/**
 * Cette fonction effectue une requête HTTP en utilisant la bibliothèque Axios.
 * @param type le type de la requête HTTP (TypeRequest)
 * @param url  à laquelle la requête doit être envoyée
 * @param payload les données à envoyer en POST ou PUT
 * @param config  la configuration de la requête
 * @returns Elle renvoie une promesse (AxiosPromise) qui peut être résolue avec les données de la réponse ou rejetée avec une erreur.
 */
function AbstractRequest(type: TypeRequest, url: string, payload: any = {}, config: any = {}): RequestPromise {
    let promise: RequestPromise;

    if (type === TypeRequest.POST) {
        promise = axios.post(url, payload, config);
    } else if (type === TypeRequest.PUT) {
        promise = axios.put(url, payload, config);
    } else if (type === TypeRequest.DELETE) {
        promise = axios.delete(url, config);
    } else {
        promise = axios.get(url, config);
    }
    return promise;
}

/**
 * Cette fonction effectue une requête HTTP GET en utilisant la bibliothèque Axios.
 * @param url  à laquelle la requête doit être envoyée
 * @param config  la configuration de la requête
 * @returns Elle renvoie une promesse (RequestPromise) qui peut être résolue avec les données de la réponse ou rejetée avec une erreur.
 */
function GetRequest(url: string, config: any = {}): RequestPromise {
    return AbstractRequest(TypeRequest.GET, url, {}, config);
}

/**
 * Cette fonction effectue une requête HTTP DELETE en utilisant la bibliothèque Axios.
 * @param url  à laquelle la requête doit être envoyée
 * @param config  la configuration de la requête
 * @returns Elle renvoie une promesse (RequestPromise) qui peut être résolue avec les données de la réponse ou rejetée avec une erreur.
 */
function DeleteRequest(url: string, config: any = {}): RequestPromise {
    return AbstractRequest(TypeRequest.DELETE, url, {}, config);
}

/**
 * Cette fonction effectue une requête HTTP POST en utilisant la bibliothèque Axios.
 * @param url  à laquelle la requête doit être envoyée
 * @param payload les données à envoyer en POST ou PUT
 * @param config  la configuration de la requête
 * @returns Elle renvoie une promesse (RequestPromise) qui peut être résolue avec les données de la réponse ou rejetée avec une erreur.
 */
function PostRequest(url: string, payload: any = {}, config: any = {}): RequestPromise {
    return AbstractRequest(TypeRequest.POST, url, payload, config);
}

/**
 * Cette fonction effectue une requête HTTP PUT en utilisant la bibliothèque Axios.
 * @param url  à laquelle la requête doit être envoyée
 * @param payload les données à envoyer en POST ou PUT
 * @param config  la configuration de la requête
 * @returns Elle renvoie une promesse (RequestPromise) qui peut être résolue avec les données de la réponse ou rejetée avec une erreur.
 */
function PutRequest(url: string, payload: any = {}, config: any = {}): RequestPromise {
    return AbstractRequest(TypeRequest.PUT, url, payload, config);
}

export {
    useGetRequest,
    usePostRequest,
    usePutRequest,
    useDeleteRequest,

    GetRequest,
    PostRequest,
    PutRequest,
    DeleteRequest
};
