import React, {useContext, useState} from "react";
import {EventUpdatedContext} from "../../../store/EventUpdatedContext";
import {LangInterface} from "../../../service/CV/Interfaces/LangInterface";
import {AddLanguage, UpdateLanguage} from "../../../service/CV/LangService";
import FCInput from "../../FCObjets/Form/FCInput";
import FCButton from "../../FCObjets/FCButton";
import {CallbackErrorModalCV, CallbackFinishedModalCV} from "./ModalCVForm";

type FormLangueProps = {
    onFinished: CallbackFinishedModalCV;
    onError: CallbackErrorModalCV;
    dataLanguage: LangInterface | undefined;
    idUserData: number;
};

const FormLanguage: React.FunctionComponent<FormLangueProps> = ({dataLanguage, idUserData, onFinished, onError}) => {

    const {setUpdated} = useContext(EventUpdatedContext);
    const [inputNom, setInputNom] = useState(dataLanguage ? dataLanguage.name : "");
    const [inputLevel, setInputLevel] = useState(dataLanguage ? dataLanguage.level : "");

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        onError(null);

        let lang: LangInterface = {
            lang_id: dataLanguage ? dataLanguage.lang_id : -1,
            level: inputLevel,
            name: inputNom,
            user_data: undefined
        };

        if (dataLanguage) {
            UpdateLanguage(idUserData, lang).then(() => {
                setUpdated();
                onFinished();
            }).catch((err) => onError(err));
        } else {
            AddLanguage(idUserData, lang).then(() => {
                setUpdated();
                onFinished();
            }).catch((err) => onError(err));
        }
    }

    return (
        <form>
            <div>
                <FCInput onChangeHandler={(e) => setInputNom(e.target.value)} label={"Nom :"} id={"Nom"}
                         value={inputNom}/>
                <FCInput onChangeHandler={(e) => setInputLevel(e.target.value)} label={"Niveau :"} id={"Niveau"}
                         value={inputLevel}/>
            </div>
            <div style={{textAlign: "right"}}>
                <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit} style={{width: "60px"}}/>
            </div>
        </form>
    );
}

export default FormLanguage;