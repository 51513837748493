import {StyleCVInterface} from "./Interfaces/Style/StyleCVInterface";
import {getToken} from "../../utils/storage/Token";
import {AppSettings} from "../../AppSettings";
import { PutRequest, RequestPromise } from "../../hook/useRequest";

const UpdateStyle = (id: number, style: StyleCVInterface) :RequestPromise => {
    const config = { headers: { Authorization : getToken() }}
    return PutRequest(AppSettings.API_CV + '/userdata/' + id.toString() + '/style/' + style.style_cv_id, style, config);
}

export {
    UpdateStyle
};
