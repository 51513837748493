import {MyStackInterface} from "../../../../service/CV/Interfaces/MyStackInterface";
import {EventUpdatedContext} from "../../../../store/EventUpdatedContext";
import React, {useContext} from "react";
import ImageStack from "./ImageStack";
import {useFetchListAllImagesStack} from "../../../../service/ImagesService";
import { ImageStackInterface } from "../../../../service/CV/Interfaces/ImageStackInterface";
import {UpdateMyStack} from "../../../../service/CV/MyStackService";
import FCSpinner from "../../../FCObjets/FCSpinner";
import FCButton from "../../../FCObjets/FCButton";
import {CallbackErrorModalCV, CallbackFinishedModalCV} from "../ModalCVForm";

type FormMyStackProps = {
    onFinished: CallbackFinishedModalCV;
    onError: CallbackErrorModalCV;
    dataStack: MyStackInterface | undefined;
    idUserData: number;
};

const FormMyStack: React.FunctionComponent<FormMyStackProps> = ({dataStack, idUserData, onFinished, onError}) => {
    const {setUpdated} = useContext(EventUpdatedContext)
    const {data, loading, error} = useFetchListAllImagesStack(idUserData);

    let m_listImageChecked: ImageStackInterface[] = [];
    if(data) {
        m_listImageChecked = [];
        for (let i = 0 ; i < data.length; i++) {
            if(data[i].check) {
                m_listImageChecked.push(data[i]);
            }
        }
    }

    /**
     * Validation du formulaire
     */
    const onSubmit = () => {
        onError(null);

        // Vérification du nombre de stack sélectionné
        if(m_listImageChecked.length > 5 || m_listImageChecked.filter(value => value.order === 0).length > 0) {
            alert("Erreur sélection stack : problème avec la définition des order (max 5 stack, ordre différent de 0)");
            return;
        }
        // Vérification des ordres
        let image1:string = getNameImageWithOrder(1);
        let image2:string = getNameImageWithOrder(2);
        let image3:string = getNameImageWithOrder(3);
        let image4:string = getNameImageWithOrder(4);
        let image5:string = getNameImageWithOrder(5);

        if (image1 === "-1" || image2 === "-1" || image3 === "-1" || image4 === "-1" || image5 === "-1") {
            console.log("verifcation image échouée !")
            return;
        }

        let stack : MyStackInterface = {
            image1: image1,
            image2: image2,
            image3: image3,
            image4: image4,
            image5: image5,
            my_stack_id: dataStack ? dataStack.my_stack_id : -1,
            user_data: undefined
        };

        UpdateMyStack(idUserData, stack).then(() => {
            setUpdated();
            onFinished();
        }).catch((err) => onError(err));
    }

    const getNameImageWithOrder = (order:number) : string => {
        let list = m_listImageChecked.filter(value => {return value.order === order})

        if (list.length > 1) {
            alert('Erreur sélection stack : Ordre mal renseigné (order=' + order + ')...');
            return "-1";
        }

        if (list.length === 0) {
            return "";
        }

        return list[0].name;
    }


    function onChangeImageStack(event: ImageStackInterface) {

        m_listImageChecked = m_listImageChecked.filter(value =>{
            return value.id !== event.id;
        });

        if (event.check) {
            m_listImageChecked.push(event)
        }
    }

    return (
        <React.Fragment>
            {
                loading && <FCSpinner size="small"/>
            }
            {
                data &&
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent:"space-around"}}>
                        <div>
                            {data.map((e: ImageStackInterface, index: number ) =>
                                index < data.length / 2 && <ImageStack imageStack={e} onChanged={(e) => onChangeImageStack(e)} key={index}/>
                            )}
                        </div>
                        <div >
                            {data.map((e :ImageStackInterface, index : number) =>
                                index > data.length / 2 && <ImageStack imageStack={e} onChanged={(e) => onChangeImageStack(e)} key={index}/>
                            )}
                        </div>
                    </div>
                    <div style={{paddingTop: "15px", display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                        <FCButton onClickHandler={onSubmit} label={dataStack ? "Modifier" : "Ajouter"}/>
                    </div>
                </div>
            }
            {
                error && <div>{error.message}</div>
            }
        </React.Fragment>
    );
}

export default FormMyStack;