import React, {useContext} from "react";
import Page from "./ResumePage/Page";
import {EditableCvProvider} from "../../store/CV/EditableCvContext";
import FloatButtons from "./FloatButtons";
import {EventUpdatedContext} from "../../store/EventUpdatedContext";
import FCSpinner from "../FCObjets/FCSpinner";
import {useFetchCV} from "../../service/CV/CVService";
import {getFirstNameWithToken, getLastNameWithToken} from "../../utils/storage/Token";
import FCAlertMsgErrorAPI from "../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import "./ContentCV.scss";
import {useTranslation} from "react-i18next";

type ContentCVProps = {
    lastname?: string;
    firstname?: string;
    editable: boolean;
};

const ContentCV: React.FunctionComponent<ContentCVProps> = (props) => {

    const {t} = useTranslation();

    let lastname = props.lastname === undefined ? "" : props.lastname;
    let firstname = props.firstname === undefined ? "" : props.firstname;

    if (props.lastname === "" || props.firstname === "") {
        lastname = getLastNameWithToken().toLowerCase();
        firstname = getFirstNameWithToken().toLowerCase();
    }

    const {cptUpdated} = useContext(EventUpdatedContext);
    const {data: cv, loading, error} = useFetchCV(firstname, lastname, cptUpdated);

    return (
        <div className={"contentCV"}>
            <div className={"div_print"} style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                <div>
                    <EditableCvProvider>
                        {loading && <FCSpinner size="small"/>}
                        {(cv) &&
                            (<Page numPage={1} data={cv} editable={props.editable}
                                   modeDoublePage={true} />)
                        }
                        {(cv && cv.userData.style && cv.userData.style.number_page === 2) &&
                            <Page numPage={2} data={cv} editable={props.editable}
                                  modeDoublePage={true}/>
                        }
                        {error &&
                            <div>
                                {error.message}
                                <FCAlertMsgErrorAPI error={error}/>
                            </div>
                        }
                    </EditableCvProvider>
                    {(cv && props.editable) &&
                        <FloatButtons userData={cv.userData}/>
                    }
                </div>

            </div>
            <div className={"notes"}>
                <p>
                    <a
                        href={"/#"}
                        onClick={(e) => {
                            e.preventDefault();
                            window.print();
                        }}
                    >
                        {t('print')}
                    </a>
                    {' '}
                    {t('print_this_page')}
                </p>
                <p>&copy; {new Date().getFullYear()} Yannick MARCHETAUX</p>
            </div>
        </div>
    );
}

ContentCV.defaultProps = {
    lastname: "",
    firstname: ""
}

export default ContentCV;