import {MyStackInterface} from "./Interfaces/MyStackInterface";
import {getToken} from "../../utils/storage/Token";
import {AppSettings} from "../../AppSettings";
import { PutRequest, RequestPromise } from "../../hook/useRequest";

const UpdateMyStack = (id: number, stack: MyStackInterface): RequestPromise=> {
    const config = {headers: {Authorization: getToken()}}
    return PutRequest(AppSettings.API_CV + '/userdata/' + id.toString() + '/mystack/' + stack.my_stack_id, stack, config);
}
export {
    UpdateMyStack
};
