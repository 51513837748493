import {createContext, useState} from "react";

interface EditableCvContextData {
    editableCV: boolean
    setEditableCV: (edit: boolean) => void
}

const EditableCvContext = createContext<EditableCvContextData>({} as EditableCvContextData);

const EditableCvProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    let [editableCV, setEdit] = useState(false);
    const setEditableCV = (edit: boolean) => {
        setEdit(edit);
    }
    return (
        <EditableCvContext.Provider value={{editableCV, setEditableCV}}>
            {children}
        </EditableCvContext.Provider>

    );
};

export {EditableCvProvider, EditableCvContext};