import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

type SensitiveInfoProps = {
    clear: boolean;
    data: string;
    data_target: string;
    icon: IconProp;
};

const SensitiveInfo: React.FunctionComponent<SensitiveInfoProps> = ({
                                                                        clear,
                                                                        data,
                                                                        data_target,
                                                                        icon,
                                                                    }) => {
    const regex = /[a-zA-Z0-9]/gi;
    let text = data.replace(regex, " - ");
    if (clear) {
        text = data;
    }

    return (
        <div className={"info"}>
            <span className={"info-value"}>
              <a href={clear ? data_target : "/#"}>{text}</a>
            </span>
            <FontAwesomeIcon className={"info-icon"} icon={icon}/>
        </div>
    );
};

export default SensitiveInfo;
