import React from 'react';
import "./FCTextArea.scss"

type FCTextAreaProps = {
    onChangeHandler: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    label: string;
    value: string;
    id: string;
    row?: number;
    width?:string;
    horizontalDisplay?:boolean;
};

const FCTextArea: React.FunctionComponent<FCTextAreaProps> = (props: FCTextAreaProps) => {

    return (
        <div className={(props.horizontalDisplay)?"horizontalFormInput":"verticalFormInput"} >
            <div style={{display: "flex", alignContent: "center", flexWrap: "wrap"}}>
                <label className={"FCTextAreaLabel"} htmlFor={props.id}>{props.label}</label>
            </div>
            <textarea className={"FCTextArea"}
                      id={props.id}
                      name={props.id}
                      rows={props.row}
                      cols={50}
                      value={props.value}
                      onChange={props.onChangeHandler}
                      style={{width:props.width}}
            ></textarea>
        </div>
    );
};

// Set default props
FCTextArea.defaultProps = {
    row: 5,
    width: "300px",
    horizontalDisplay:false
};

export default FCTextArea;
