import React, {useContext, useState} from "react";
import Bezel from "../Design/Bezel";
import PersonalData from "./PersonalData";
import I18nTextNode from "../../I18nTextNode";
import {PersonalDataInterface} from "../../../../service/CV/Interfaces/PersonalDataInterface";
import {TypeForm} from "../../../../types/EnumTypeForm";
import ModalCVForm from "../../Form/ModalCVForm";
import {EditableCvContext} from "../../../../store/CV/EditableCvContext";
import ManageStyleCV from "../../../../utils/CV/ManageStyleCV";

type HeaderProps = {
    personalData: PersonalDataInterface;
};

const Header: React.FunctionComponent<HeaderProps> = ({personalData}) => {
    const [typeForm, setTypeForm] = useState(TypeForm.EMPTY);
    const {editableCV} = useContext(EditableCvContext);

    let colorBackHeader = new ManageStyleCV(personalData?.user_data?.style).getBackgroundHeader();

    return (
        <header>
            <div className={editableCV ? "header-bg hover-form" : "header-bg"} style={{backgroundColor:colorBackHeader}}
                 onClick={() => editableCV && setTypeForm(TypeForm.HEADER)}>
                <div className={"header-content"} >
                    <div className={"name"}>
                        <h1>
                            {personalData.user_data.userCnx.firstname} {personalData.user_data.userCnx.lastname}
                        </h1>
                        <h2>
                            <I18nTextNode text={personalData.title}/>
                        </h2>
                    </div>
                    <PersonalData personalData={personalData} backgroundColor={colorBackHeader}/>
                </div>
            </div>
            <Bezel height={3} color={colorBackHeader} right={true} base={1}/>
            <ModalCVForm typeForm={typeForm} onModalFormFinished={() => setTypeForm(TypeForm.EMPTY)} data={personalData}
                         idUserData={personalData.user_data ? personalData.user_data.user_data_id : -1}/>
        </header>
    );
};

export default Header;
