import React, {useEffect, useState} from "react";
import {faBirthdayCake, faCar, faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import SensitiveInfo from "./SensitiveInfo";
import I18nTextNode from "../../I18nTextNode";
import Info from "./Info";
import {useTranslation} from "react-i18next";

import {PersonalDataInterface} from "../../../../service/CV/Interfaces/PersonalDataInterface"

const PersonalData = ({personalData, backgroundColor}: { personalData: PersonalDataInterface, backgroundColor:string }) => {
    const [clear, setClear] = useState(false);
    useEffect(() => {
        window.onbeforeprint = () => {
            setClear(true);
        };
        return () => {
            window.onbeforeprint = null;
        };
    }, [setClear]);

    const {t} = useTranslation();

    return (
        <div className={"infos"}>
            <SensitiveInfo data={personalData.user_data.mail} data_target={personalData.user_data.target_mail}
                           icon={faEnvelope} clear={clear}/>
            {<Info icon={faBirthdayCake}>{personalData.user_data.age} ans</Info>}
            <SensitiveInfo data={personalData.user_data.phone} data_target={personalData.user_data.target_phone}
                           icon={faPhone} clear={clear}/>
            <Info icon={faCar}>
                <I18nTextNode text={personalData.license}/>
            </Info>
            {!clear && (
                <div style={{backgroundColor:backgroundColor, opacity:0.8}} className={"placeholder"} onClick={() => setClear(true)}>
                    <h3>
                        {t('contact_me')}
                    </h3>
                    <p>
                        {t('contact_part_1')}
                    </p>
                    <p>
                        {t('contact_part_2')}
                    </p>
                </div>
            )}
        </div>
    );
};

export default PersonalData;
