import React, {useState} from "react";
import {clearToken} from "../../../utils/storage/Token";
import {DelResetBddCV} from "../../../service/AdminService";
import "./HeaderDashboard.scss"
import {PostRequest} from "../../../hook/useRequest";

type HeaderDashboardProps = {
    setMenuSelected: any;
};

const HeaderDashboard: React.FunctionComponent<HeaderDashboardProps> = ({setMenuSelected}) => {
    const [hamburgerToggle, setHamburgerToggle] = useState(false);

    const onClick = async (typeMenu: string) => {
        if (typeMenu === "logout") {
            await PostRequest("/authentication/logout", {}).then(() => {
                clearToken();
                window.location.reload();
            }).catch(error => console.log(error));
        } else {
            setMenuSelected(typeMenu);
        }
        setHamburgerToggle(false);
    }
    const toggleHamburger = () => {
        setHamburgerToggle(!hamburgerToggle);
    }

    const clickAdminReset = async (menu: string) => {
        if (menu === "CV") {
            DelResetBddCV().then(() => {
                window.location.reload();
            }).catch(error => alert(error));
        }
    }

    return (
        <nav className={"headerDashboard"}>
            <div className={"headerLogo"}>
                <img src={`${process.env.PUBLIC_URL}/logo_app_cv.png`} style={{cursor: "pointer"}}
                     alt={"logo_fc"} height={45} width={45} onClick={() => {
                    window.location.reload()
                }}/>
            </div>
            <div className={"headerTitle"} onClick={() => {
                window.location.reload()
            }}>GESTION CV
            </div>
            <div className={hamburgerToggle ? "menuBar show" : "menuBar"}>
                <div className={"spacerHeader"}></div>
                <div className={"menuItem"} onClick={() => onClick("password")}>Modification du mot de passe</div>
                <div className={"spacerHeader"}></div>
                <div className={"menuItem"} onClick={() => clickAdminReset("CV")}>Reset CV</div>
                <div className={"spacerHeader"}></div>
                <div className={"menuItem"} onClick={() => onClick("logout")}>Logout</div>
                <div className={"spacerHeader"}></div>
            </div>
            <div className="menuToggle" onClick={() => toggleHamburger()}>
                <span className="iconBar"></span>
                <span className="iconBar"></span>
                <span className="iconBar"></span>
            </div>
        </nav>
    );
}

export default HeaderDashboard;