export const enum TypeForm {
    EMPTY               = "",

    // CV
    EXP                 = "experience",
    FORMATION           = "formation",
    HARD_SKILLS         = "hardskills",
    SOFT_SKILLS         = "softskills",
    MYSTACK             = "mystack",
    LANGUAGE            = "lang",
    HEADER              = "header",
    FOOTER              = "footer",
    STYLE               = "style",
    PROJECT             = "project"
}