import React, {useState} from "react";
import "./ContentModifyPass.scss"
import FCInput from "../../../FCObjets/Form/FCInput";
import FCButton from "../../../FCObjets/FCButton";
import {getIdWithToken} from "../../../../utils/storage/Token";
import {UpdateUserInfo} from "../../../../service/AdminService";
import FCAlertMsg, {TypeAlertMsg} from "../../../FCObjets/FCAlertMsg/FCAlertMsg";
import jwt from "jsonwebtoken";

type ContentModifyPassProps = {};

const ContentModifyPass: React.FunctionComponent<ContentModifyPassProps> = () => {

    const [inputPassword, setInputPassword] = useState("");
    const [inputPasswordVerify, setInputPasswordVerify] = useState("");
    const [diff, setDiff] = useState(false);
    const [error, setError] = useState<any>(null);

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        setError(null);

        if (diff) {
            setError({message: "Mot de passe différent"});
            return;
        }

        let userInfo: {} = {
            id: getIdWithToken(),
            password: jwt.sign({pass: inputPassword}, "privateKeyPass"),
        };

        UpdateUserInfo(getIdWithToken(), userInfo).then(() => {
            alert("Mot de passe à jour");
            window.location.reload();
        }).catch((e) => {
            setError(e)
        });
    }


    /**
     * Évènement de mise à jour des champs
     */
    const eventChangePass = (value: string, inputVerify: boolean) => {
        setError(null);

        if (inputVerify) {
            setInputPasswordVerify(value);
        } else {
            setInputPassword(value);
        }

        if ((inputVerify && value !== inputPassword) ||
            (!inputVerify && value !== inputPasswordVerify)) {
            setDiff(true);
        } else {
            setDiff(false);
        }
    }

    return (
        <React.Fragment>
            <h1 className="content-heading"> Modification du mot de passe </h1>
            <form className={"content-page-account"}>
                <div>
                    <FCInput onChangeHandler={(e) => eventChangePass(e.target.value, false)} label={"Mot de passe"}
                             id={"pass1"}
                             value={inputPassword} widthInput={"300px"} colorTextLabel={"#2f3640"}
                             colorInput={diff ? "#2f3640" : undefined} type={"password"}
                             widthLabel={"200px"} backgroundColor={diff ? "#E74C3C" : undefined}/>
                    <FCInput onChangeHandler={(e) => eventChangePass(e.target.value, true)} label={"Vérification"}
                             id={"pass2"}
                             value={inputPasswordVerify} widthInput={"300px"} colorTextLabel={"#2f3640"}
                             colorInput={diff ? "#2f3640" : undefined} type={"password"}
                             widthLabel={"200px"} backgroundColor={diff ? "#E74C3C" : undefined}/>
                </div>
                <div style={{textAlign: "right", marginBottom:"15px", marginRight:"15px"}}>
                    <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit}/>
                </div>
            </form>
            {error &&
                <div>
                    <FCAlertMsg textMsg={error.message} type={TypeAlertMsg.ERROR}
                                activate={!!error} title={"Erreur API"}/>
                </div>
            }

        </React.Fragment>
    )
}

export default ContentModifyPass;