import React from "react";
import "./FCInput.scss";

type InputProps = {
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    value: string | number | undefined;
    id: string
    type?: string;
    min?: number;
    max?: number;
    widthInput?: string;
    widthLabel?: string;
    colorTextLabel?: string;
    accept?: string;
    backgroundColor?: string;
    colorInput?: string;
    horizontalDisplay?:boolean;
};

const FCInput: React.FunctionComponent<InputProps> = (props: InputProps) => {

    return (
        <div className={(props.horizontalDisplay)?"horizontalFormInput":"verticalFormInput"}>
            <label className={"FCInputLabel"} htmlFor={props.id}
                   style={{width: props.widthLabel, color: props.colorTextLabel}}>{props.label}</label>
            <input className={"FCInput"}
                   type={props.type} id={props.id} value={props.value}
                   onChange={props.onChangeHandler}
                   min={props.min} max={props.max} name={props.id}
                   style={{width: props.widthInput, backgroundColor: props.backgroundColor, color: props.colorInput}}
                   onKeyDown={props.onKeyDown} accept={props.accept}/>
        </div>
    );
}

// Set default props
FCInput.defaultProps = {
    type: "text",
    min: 0,
    max: 100,
    widthInput: "300px",
    widthLabel: "110px",
    colorTextLabel: undefined,
    backgroundColor: undefined,
    colorInput: undefined,
    horizontalDisplay:false
};

export default FCInput;