import {UserDataInterface} from "./Interfaces/UserDataInterface";
import {getToken} from "../../utils/storage/Token";
import {AppSettings} from "../../AppSettings";
import { PutRequest, RequestPromise } from "../../hook/useRequest";

const UpdateUserData = (user: UserDataInterface) : RequestPromise => {
    const config = { headers: { Authorization : getToken() }}
    return PutRequest(AppSettings.API_CV + '/userdata/' + user.user_data_id.toString(), user, config);
}

export {
    UpdateUserData,
};
