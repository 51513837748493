import {ExperienceInterface} from "./Interfaces/ExperienceInterface";
import {getToken} from "../../utils/storage/Token";
import {AppSettings} from "../../AppSettings";
import { PostRequest, PutRequest, RequestPromise } from "../../hook/useRequest";

const AddExperience = (id: number, exp: ExperienceInterface) : RequestPromise => {
    const config = {headers: {Authorization: getToken()}}
    return PostRequest(AppSettings.API_CV + '/userdata/' + id.toString() + '/experience', exp, config);
}

const UpdateExperience = (id: number, exp: ExperienceInterface): RequestPromise => {
    const config = {headers: {Authorization: getToken()}}
    return PutRequest(AppSettings.API_CV + '/userdata/' + id.toString() + '/experience/' + exp.experience_id, exp, config);
}

export {
    AddExperience,
    UpdateExperience
};
