import {AddExperience, UpdateExperience} from "../../../service/CV/ExperienceService";
import {ExperienceInterface} from "../../../service/CV/Interfaces/ExperienceInterface";
import React, {useContext, useState} from "react";
import {DisplayLevel} from "../../../types/CV/EnumDisplayLevel";
import {EventUpdatedContext} from "../../../store/EventUpdatedContext";
import FCTextArea from "../../FCObjets/Form/FCTextArea";
import FCInput from "../../FCObjets/Form/FCInput";
import FCDatePicker from "../../FCObjets/Form/FCDatePicker";
import FCButton from "../../FCObjets/FCButton";
import FCDropdown from "../../FCObjets/Form/FCDropdown";
import {CallbackErrorModalCV, CallbackFinishedModalCV} from "./ModalCVForm";

type FormExperiencesListProps = {
    onFinished: CallbackFinishedModalCV;
    onError: CallbackErrorModalCV;
    dataExperience: ExperienceInterface | undefined;
    idUserData: number;
};

const FormExperience:React.FunctionComponent<FormExperiencesListProps> = ({dataExperience, idUserData, onFinished, onError}) => {
    const {setUpdated} = useContext(EventUpdatedContext)

    const [inputPoste, setInputPoste] = useState(dataExperience ? dataExperience.title : "");
    const [inputProjectName, setInputProjectName] = useState(dataExperience ? dataExperience.projet : "");
    const [inputPlace, setInputPlace] = useState(dataExperience ? dataExperience.place : "");
    const [inputCompany, setInputCompany] = useState(dataExperience ? dataExperience.company : "");
    const [inputExp, setInputExp] = useState(dataExperience ? dataExperience.description : "");
    const [inputTech, setInputTech] = useState(dataExperience ? dataExperience.tech : "");
    const [inputDisplayLevel, setInputDisplayLevel] = useState(dataExperience ? dataExperience.displayLevel : "");
    const [inputPage, setInputPage] = useState(dataExperience ? dataExperience.page : 1);
    const [dateBegin, setDateBegin] = useState(dataExperience ? new Date(dataExperience.begin) : null);
    const [dateEnd, setDateEnd]
        = useState((dataExperience &&
        dataExperience.end !== null && dataExperience.end !== undefined && dataExperience.end !== "") ? new Date(dataExperience.end) : null);

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        onError(null);

        let exp : ExperienceInterface = {
            experience_id: dataExperience ? dataExperience.experience_id : -1,
            user_data:  undefined,
            title:      inputPoste,
            projet:     inputProjectName,
            place:      inputPlace,
            company:    inputCompany,
            displayLevel: inputDisplayLevel,
            description: inputExp,
            tech:       inputTech,
            lang:       "fr",
            begin:      dateBegin ? dateBegin.toDateString() : "",
            end:        dateEnd ? dateEnd.toDateString() : "",
            page:       inputPage
        };

        if(dataExperience) {
            UpdateExperience(idUserData, exp).then(() => {
                setUpdated();
                onFinished();
            }).catch((err) => onError(err));
        }
        else {
            AddExperience(idUserData, exp).then(() => {
                setUpdated();
                onFinished();
            }).catch((err) => onError(err));
        }
    }

    const options = [
        { value: DisplayLevel.FULL, label: DisplayLevel.FULL },
        { value: DisplayLevel.COMPACT, label: DisplayLevel.COMPACT },
        { value: DisplayLevel.NONE, label: DisplayLevel.NONE },
    ];

    const optionsPage = [
        { value: "1", label: "Page 1" },
        { value: "2", label: "Page 2" },
    ];

    return (
        <form>
            <div>
                <FCInput onChangeHandler={(e) => setInputPoste(e.target.value)} label={"Poste occupé :"} id={"post"}
                         value={inputPoste} widthInput={"400px"}/>
                <FCInput onChangeHandler={(e) => setInputProjectName(e.target.value)} label={"Nom du projet :"} widthLabel={"500px"} id={"porject_name"}
                         value={inputProjectName} widthInput={"500px"}/>
                <FCDropdown onChangeHandler={(e) => setInputPage(parseInt(e.target.value))} options={optionsPage}
                            label={"Page :"} id={"page"} value={inputPage} widthDropdown={"500px"}/>
                <FCInput onChangeHandler={(e) => setInputPlace(e.target.value)} label={"Localisation :"} id={"place"}
                         value={inputPlace} widthInput={"500px"}/>
                <FCInput onChangeHandler={(e) => setInputCompany(e.target.value)} label={"Entreprise :"} id={"company"}
                         value={inputCompany} widthInput={"500px"}/>
                <FCTextArea onChangeHandler={(e) => setInputExp(e.target.value)} label={"Détails du projet :"} id={"detail"}
                         value={inputExp} width={"500px"} row={9}/>
                <FCDatePicker onChangeHandler={setDateBegin} value={dateBegin} id={"deb"} label={"Début :"}/>
                <FCDatePicker onChangeHandler={setDateEnd} value={dateEnd} id={"fin"} label={"Fin :"}/>
                <FCInput onChangeHandler={(e) => setInputTech(e.target.value)} label={"Technologies :"} id={"tech"}
                         value={inputTech} widthInput={"500px"}/>
                <FCDropdown onChangeHandler={(e) => setInputDisplayLevel(e.target.value)} options={options}
                            label={"Display Level :"} id={"displayLevel"} value={inputDisplayLevel} widthDropdown={"500px"}/>
            </div>
            <div style={{textAlign: "right"}}>
                <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit}/>
            </div>
        </form>
    );
}

export default FormExperience;