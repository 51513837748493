import {FormationInterface} from "./Interfaces/FormationInterface";
import {getToken} from "../../utils/storage/Token";
import {AppSettings} from "../../AppSettings";
import { PostRequest, PutRequest, RequestPromise } from "../../hook/useRequest";

const AddFormation = (id: number, form: FormationInterface):RequestPromise => {
    const config = {headers: {Authorization: getToken()}}
    return PostRequest(AppSettings.API_CV + '/userdata/' + id.toString() + '/formation', form, config);
}

const UpdateFormation = (id: number, form: FormationInterface):RequestPromise => {
    const config = {headers: {Authorization: getToken()}}
    return PutRequest(AppSettings.API_CV + '/userdata/' + id.toString() + '/formation/' + form.formation_id, form, config);
}

export {
    AddFormation,
    UpdateFormation
};
