import {faHeart} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useContext, useState} from "react";
import I18nTextNode from "../../I18nTextNode";
import {TypeForm} from "../../../../types/EnumTypeForm";
import ModalCVForm from "../../Form/ModalCVForm";
import {EditableCvContext} from "../../../../store/CV/EditableCvContext";
import {AppSettings} from "../../../../AppSettings";
import {MyStackInterface} from "../../../../service/CV/Interfaces/MyStackInterface";

type MyStackProps = {
    dataMyStack: MyStackInterface;
};

const MyStack: React.FunctionComponent<MyStackProps> = ({dataMyStack}) => {

    const [typeForm, setTypeForm] = useState(TypeForm.EMPTY);
    const {editableCV} = useContext(EditableCvContext);

    return (
        <div>
            <div className={"col-section"}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <div style={{padding: "left", paddingRight: "3px"}}>
                        <h2 className={"col-title"}>
                            <I18nTextNode text={{fr: "Ma", en: "My"}}/>{" "}
                            <FontAwesomeIcon icon={faHeart} className={"red"}/> stack
                        </h2>
                    </div>
                    <div className={"col-barre"}/>

                </div>
                <div className={editableCV ? "hover-form" : ""}
                     onClick={() => editableCV && setTypeForm(TypeForm.MYSTACK)}>
                    <div className={"logo-line"}>
                        <img
                            src={AppSettings.API_ENDPOINT + `/images/stack/` + dataMyStack.image1}
                            className={"logo-line"}
                            alt={dataMyStack.image1}
                            height={30}
                        />
                        <img src={AppSettings.API_ENDPOINT + `/images/stack/` + dataMyStack.image2}
                             className={"logo-line"}
                             alt={dataMyStack.image2} height={30}/>
                        <img
                            src={AppSettings.API_ENDPOINT + `/images/stack/` + dataMyStack.image3}
                            className={"logo-line"}
                            alt={dataMyStack.image3}
                            height={30}
                        />
                    </div>
                    {(dataMyStack.image4 || dataMyStack.image5) &&
                        <div className={"logo-line"}>
                            {dataMyStack.image4 &&
                                <img src={AppSettings.API_ENDPOINT + `/images/stack/` + dataMyStack.image4}
                                     className={""}
                                     alt={dataMyStack.image4} height={30}/>
                            }
                            {dataMyStack.image5 &&
                                <img src={AppSettings.API_ENDPOINT + `/images/stack/` + dataMyStack.image5}
                                     className={""}
                                     alt={dataMyStack.image5} height={30}/>
                            }
                        </div>
                    }
                </div>

            </div>
            <ModalCVForm typeForm={typeForm} onModalFormFinished={() => setTypeForm(TypeForm.EMPTY)}
                         data={dataMyStack} idUserData={dataMyStack.user_data ? dataMyStack.user_data.user_data_id : -1}/>
        </div>
    );
};

export default MyStack;
